import React, { useEffect, useState } from 'react';
import './../stylesheet/course.css';
import './../stylesheet/button.css'
import StarRating from './starRatings'; // Assuming you have this component
import logo from './../../images/logo.png';
import { useLocation, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

function formatDate(dateString) {
  const options = { day: 'numeric', month: 'numeric', year: 'numeric' };
  const formattedDate = new Date(dateString).toLocaleDateString(undefined, options);
  return formattedDate;
}

const CoursePage = () => {
  const [courseData, setCourseData] = useState({});
  const [imgURL, setImgURL] = useState(logo);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const navigate = useNavigate();
  const subjectID = queryParams.get('subjectID');
  const userID = queryParams.get('userID');
  const programmeID = queryParams.get('programmeID');
  const universityID = queryParams.get('universityID');

  const user = useSelector((state) => state.user);

  const calcAvg = (array, prop) => {
    if (array.length == 0) {
        return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
        total += array[i][prop];
    }
    let avg = total / array.length;
    return avg.toFixed(1);
}

  const calcSum = (array, prop) => {
    if (array.length == 0) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
      total += array[i][prop];
    }
    return total;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/courseRoute/`, {
          params: {
            subjectID: subjectID,
            userID: userID,
            programmeID: programmeID,
            universityID: universityID,
          },
        });
        if (response.data.user.isBanned) {
          navigate('/');
        }
        // Update state with the new data
        setCourseData(response.data);
        fetchAndDisplayImage(response.data.user.user_picture);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, [subjectID, userID, programmeID, universityID]);

  function refreshPage() {
    window.location.reload(false);
  }

  const fetchAndDisplayImage = async (filename) => {
    try {
      // Fetch the image from the server
      const imageUrl = `http://localhost:5000/files/${filename}`;
      const response = await fetch(imageUrl);

      if (response.ok) {
        // Convert the response to blob and create an object URL
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        setImgURL(objectUrl);

      } else {
        console.error('Error fetching image:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const handleDeactivateCourse = async (course) => {
    try {
      const response = await axios.post('http://localhost:5000/api/deactivateCourse/', course.course);
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        // Check if the update was successful
        refreshPage();
      } else {
        // Display an error message based on the response status
        console.error('Deactivation unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const handleDeleteCourse = async (course) => {
    try {
      const response = await axios.post('http://localhost:5000/api/delCourse/', course.course);
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        // Check if the update was successful
        refreshPage();
      } else {
        // Display an error message based on the response status
        console.error('Deactivation unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  return (
    <div className="course-page">
      {courseData.user && (
        <>
          {/* Tutor Section */}
          <div className="tutor-section">
            <img className="profile-picture" src={imgURL} alt="Profile" />
            <h2>Tutor: {courseData.user.user_fname} {courseData.user.user_lname}</h2>
            {/* You can add more details about the tutor here */}
            <div className='tutor-rating'>
              <StarRating rating={calcAvg(courseData.ratings, "rating")} />
            </div>
            <p>CGPA: {courseData.userEdu.grades}</p>
            <p>Grade Obtained: {courseData.course.grade}</p>
            <p>Number of Students: {courseData.students.length}</p>
            <p>Number of Hours Taught: {calcSum(courseData.students, "class_hour")}</p>
            <p>Hourly Rate: RM {courseData.course.price.$numberDecimal}</p>
            <p className='free-trial'>{courseData.course.free_trial} Minutes Free Trial</p>
          </div>
          <div className="right-section">
            {/* Title Section */}
            <div className="title-section">
              <h1>{courseData.subject.subject_code} - {courseData.subject.subject_name}</h1>
              <p>Mode of Class:
                {courseData.course.offline_enable && (
                  <>
                    &nbsp;Physical&nbsp;
                  </>
                )}
                {courseData.course.online_enable && (
                  <>
                    &nbsp;Online&nbsp;
                  </>
                )}
              </p>
              {(courseData.user.user_email !== user) && (user !== 'admin') && (!courseData.course.isDeleted) && (!courseData.course.isDeactivated) && (
                <div className="title-actions">
                  <a href={`/payment?subjectID=${courseData.subject.subject_id}&userID=${courseData.user.user_ID}&programmeID=${courseData.programme.programme_id}&universityID=${courseData.university.university_id}`} >
                    <button className="action-button book-lesson-btn">Book Lesson</button></a>
                </div>
              )}
              {(user === 'admin') && (
                <div className="title-actions">
                  {courseData.course.isDeactivated ? (
                    <button onClick={() => handleDeactivateCourse(courseData)} className="action-button book-lesson-btn">Reactivate Lesson</button>
                  ) : (
                    <button onClick={() => handleDeactivateCourse(courseData)} className="action-button book-lesson-btn">Deactivate Lesson</button>
                  )}
                  {courseData.course.isDeleted ? (
                    <button onClick={() => handleDeleteCourse(courseData)} className="action-button book-lesson-btn">Restore Lesson</button>
                  ) : (
                    <button onClick={() => handleDeleteCourse(courseData)} className="action-button book-lesson-btn">Delete Lesson</button>
                  )}
                    </div>
              )}
              {((user !== 'admin') && (courseData.course.isDeleted) || (courseData.course.isDeactivated)) && (
                <div className="title-actions">
                  <p>This course is unavailable at the moment.</p>
                </div>
              )}
            </div>

            {/* Course Section */}
            <div className="course-section">
              <h2>About The Tutor</h2>
              <p style={{ whiteSpace: 'pre-line' }} >{courseData.course.description}</p>
            </div>

            {/* Reviews Section */}
            <div className="reviews-section">
              <h2>Reviews</h2>
              {courseData.ratings.map((review) => (
                <div key={review.id} className="review-item">
                  <div className="starBox">
                    <StarRating rating={review.rating} />
                    <p className='reviewer'>{courseData.reviewers[courseData.ratings.indexOf(review)].user_fname} {courseData.reviewers[courseData.ratings.indexOf(review)].user_lname} - {formatDate(review.date)}</p>
                  </div>
                  <p>{review.review}</p>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
    </div>
  );
};

export default CoursePage;
