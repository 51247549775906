import './../../stylesheet/user/listing.css'
import './../../stylesheet/button.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './../../../images/logo.png';
import { useSelector } from 'react-redux';
import StarRating from './../starRatings.js';
import { useLocation } from 'react-router-dom';
import PasswordModal from './../user/passwordModal';

const CourseModal = ({ handleUpdate, user, closeModal, object }) => {
  const [education, setEducation] = useState([]);
  const [subjects, setSubjects] = useState([]);
  const [programme, setProgramme] = useState('');
  const [subject, setSubject] = useState('');
  const [description, setDescription] = useState('');
  const [grades, setGrades] = useState('');
  const [price, setPrice] = useState(0);
  const [offerFreeTrial, setOfferFreeTrial] = useState(false);
  const [onlineMode, setOnlineMode] = useState(false);
  const [physicalMode, setPhysicalMode] = useState(false);
  const [freeTrialMinutes, setFreeTrialMinutes] = useState(0);
  const [error, setError] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);

  useEffect(() => {

    const fetchEducation = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/getprogramme/${user.user_ID}`);
        setEducation(response.data);
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };
    const fetchSubject = async () => {
      await fetchEducation().then(() => setDataLoaded(true)); // Set dataLoaded to true after universities are fetched
      if (object) {
        setProgramme(object.programme.programme_id);
        setSubject(object.subject.subject_id);
        setDescription(object.course.description);
        setGrades(object.course.grade);
        setPrice(object.course.price.$numberDecimal);
        setOfferFreeTrial(object.course.free_trial > 0);
        setFreeTrialMinutes(object.course.free_trial);
        setOnlineMode(object.course.online_enable);
        setPhysicalMode(object.course.offline_enable);
        setError('');
      }
    };

    fetchSubject();
  }, []);

  useEffect(() => {
    const loadSubjects = async () => {
      if (education) {
        try {
          const matchingProgramme = education.find(item => item.programme && item.programme.programme_id === programme);

          if (!matchingProgramme) {
            return []; // If no matching programme is found, return an empty array
          }
          const subjects = matchingProgramme.subjects || [];
          setSubjects(subjects);
        } catch (error) {
          console.error(`Error fetching subjects for programme ${programme}:`, error);
        }
      }
    };

    loadSubjects();

  }, [programme]);

  useEffect(() => {
    const remainData = async () => {

      if (object) {
        setError("Cannot edit Subject and Grades. Delete course if needed.");
        setProgramme(object.programme.programme_id);
        setSubject(object.subject.subject_id);
        setGrades(object.course.grade);
      }
    };

    const loadSubjects = async () => {
      if (education && programme) {
        try {
          // Find the matching programme in the education data
          const matchingProgramme = education.find(item => item.programme && item.programme.programme_id === programme);

          if (matchingProgramme) {
            // If a matching programme is found, set the subjects based on that programme
            const subjects = matchingProgramme.subjects || [];
            setSubjects(subjects);
          } else {
            // If no matching programme is found, set subjects to an empty array
            setSubjects([]);
          }
        } catch (error) {
          console.error(`Error fetching subjects for programme ${programme}:`, error);
        }
      }
    };


    loadSubjects();

    //fetchUniversities().then(() => setDataLoaded(true)); // Set dataLoaded to true after universities are fetched
    remainData();

  }, [education, programme, subject, grades]);

  const handleRadioChange = (e) => {
    setOfferFreeTrial(e.target.value === 'yes');
  };

  const handleOnlineChange = (e) => {
    setOnlineMode(!onlineMode);
  };

  const handlePhysicalChange = (e) => {
    setPhysicalMode(!physicalMode);
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!programme || !subject || !description || !grades || !price || (!onlineMode && !physicalMode)) {

      setError('Please fill in all required fields. At least one mode of class');
      return;
    }

    const checkDup = async () => {
      try {
        const response = await axios.get(`http://localhost:5000/api/getcourse/${user.user_ID}/${subject}`);

        if (response.status === 201) {
          console.log("Duplicate course found");
          return true; // A course with the same user ID and subject ID exists
        } else if (response.status === 404) {
          console.log("No duplicate course found");
          return false; // No course found, no duplicate
        } else {
          console.error('Unexpected response status:', response.status);
          return false; // Return false in case of unexpected response status
        }
      } catch (error) {
        console.error('Error fetching course data:', error);
        return false; // Return false in case of error
      }
    };

    const isDuplicate = await checkDup();

    if (isDuplicate == true && object == null) {
      setError('Duplicate course found. Please delete existing one before creating new one.');
      return;
    }
    // Additional validation logic can be added here

    // Call the parent component's submit function

    try {
      let url;
      if (object) {
        url = 'http://localhost:5000/api/editCourse/';
      } else {
        url = 'http://localhost:5000/api/saveCourse/';
      }
      const response = await axios.post(url, {
        user_ID: user.user_ID,
        subject_id: subject,
        description: description,
        price: price,
        free_trial: freeTrialMinutes,
        offline_enable: physicalMode,
        online_enable: onlineMode,
        grade: grades
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        // Reset form fields
        setProgramme('');
        setSubject('');
        setDescription('');
        setGrades('');
        setPrice('');
        setOfferFreeTrial(false);
        setFreeTrialMinutes(0);
        setOnlineMode(false);
        setPhysicalMode(false);
        setError('');

        handleUpdate();

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  if (!dataLoaded) {
    // Return a loading indicator while data is being fetched
    return <div>Loading...</div>;
  }

  return (
    <div className='create-course-modal'>
      <div className="create-course-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="programme">Programme:</label>
            <select
              id="programme-form"
              value={programme}
              onChange={(e) => setProgramme(e.target.value)}
            >
              <option value="">Select Programme</option>
              {/* Add options for programmes here */}
              {education.map((edu) => (
                <option value={edu.programme.programme_id}>{edu.university.university_abbr} - {edu.programme.programme_name}</option>
              ))}
            </select>
          </div>
          <div className="form-education">
            <label htmlFor="subject">Subject:</label>
            <select
              id="subject-form"
              value={subject}
              onChange={(e) => setSubject(e.target.value)}
            >
              <option value="">Select Subject</option>
              {/* Add options for subjects here */}
              {subjects.map((module) => (
                <option key={module.subject_id} value={module.subject_id}>{module.subject_code} - {module.subject_name}</option>
              ))}
            </select>
          </div>
          <div className="form-education">
            <label htmlFor="description">Description:</label>
            <textarea
              id="description-form"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              rows={5}
              cols={40}
            />
          </div>
          <div className="form-education">
            <label htmlFor="grades">Grades:</label>
            <input
              type="text"
              id="grades-form"
              value={grades}
              onChange={(e) => setGrades(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="price">Price/Hour:</label>
            <input
              type="number"
              id="price-form"
              value={price}
              onChange={(e) => setPrice(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label>Offer Free Trial:</label>
            <div id='free-trial'>
              <div className='free-trial-item'>
                <label>
                  Yes
                </label>
                <input
                  type="radio"
                  value="yes"
                  checked={offerFreeTrial}
                  onChange={handleRadioChange}
                />
              </div>
              <div className='free-trial-item'>
                <label>
                  No
                </label>
                <input
                  type="radio"
                  value="no"
                  checked={!offerFreeTrial}
                  onChange={handleRadioChange}
                />
              </div>
            </div>
          </div>
          {offerFreeTrial && (
            <div>
              <label htmlFor="freeTrialMinutes">Free Trial Minutes: </label>
              <input
                type="number"
                id="freeTrialMinutes"
                value={freeTrialMinutes}
                onChange={(e) => setFreeTrialMinutes(e.target.value)}
              />
            </div>
          )}
          <div className="form-education">
            <label>Mode of Class:</label>
            <div id='class-mode'>
              <div className='class-mode-item'>
                <label>
                  Online
                </label>
                <input
                  type="checkbox"
                  checked={onlineMode}
                  onChange={handleOnlineChange}
                />
              </div>
              <div className='class-mode-item'>
                <label>
                  Physical
                </label>
                <input
                  type="checkbox"
                  checked={physicalMode}
                  onChange={handlePhysicalChange}
                />
              </div>
            </div>
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit Course</button>
            <button className='action-button cancel-button' type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Listings = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null);

  const location = useLocation();
  const [placeholderString, setPlaceholderString] = useState('Searching results..');
  const [showModal, setShowModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showPasswordModal, setShowPasswordModal] = useState(false);

  const user = useSelector((state) => state.user);

  const calcAvg = (array, prop) => {
    if (array.length == 0) {
        return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
        total += array[i][prop];
    }
    let avg = total / array.length;
    return avg.toFixed(1);
}

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          setUserData(userData);

          try {
            // Make a POST request to the server with the user's email
            console.log("This is reached");
            const userResponse = await axios.post(`http://localhost:5000/api/searchRoute2/`, {
              user_ID: userData.user_ID
            });

            // Assuming the server responds with the user data
            console.log(userResponse.data)
            setSearchResults(userResponse.data);
            setPlaceholderString("No courses made yet. Make one and start teaching now!")

          } catch (error) {
            console.error('Error fetching user data:', error);
          }

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(searchResults.map(async (result) => {
        if (result.user && result.user.user_picture !== '') {
          try {
            const imageUrl = `http://localhost:5000/files/${result.user.user_picture}`;
            const response = await fetch(imageUrl);
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              console.error('Error fetching image:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          return logo;
        }
      }));
      setImageUrls(urls);
    };

    fetchImages();
  }, [searchResults]);

  // Function to handle opening the modal
  const openModal = () => {
    setShowModal(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  const openEditModal = (object) => {
    setSelectedCourse(object);
    setShowEditModal(true);
  };

  // Function to handle closing the modal
  const closeEditModal = () => {
    setShowEditModal(false);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  // Function to handle submitting the course creation form
  const handleCreateCourse = () => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    closeModal();
    refreshPage();
  };

  const handleDeactivateCourse = async (course) => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    try {
      const response = await axios.post('http://localhost:5000/api/deactivateCourse/', course.course);
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        // Check if the update was successful
        refreshPage();
      } else {
        // Display an error message based on the response status
        console.error('Deactivation unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  const openPasswordModal = (object) => {
    setSelectedCourse(object);
    setShowPasswordModal(true);
  };

  // Function to handle closing the modal
  const closePasswordModal = () => {
    setShowPasswordModal(false);
  };

  const handleDeleteWithVerification = async (password) => {
    // Implement your logic to verify the password and handle the update
    // Call your update function here
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: user,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        const updateResponse = await axios.post('http://localhost:5000/api/delCourse/', selectedCourse);
        // Check if the update was successful
        if (updateResponse.status === 200) {
          closePasswordModal(); // Close the modal after successful update
          window.location.reload(false);
        } else {
          console.error('Delete unsuccessful');
        }

      } else {
        // Display an error message based on the response status
        console.error('verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  return (
    <div className="listing-page">
      <div>
        <a
          className="action-button create-button" onClick={openModal}
        >
          Create Course
        </a>
      </div>
      <div className='sorting-and-info-listing-container'>
        {/*<div className="sorting-listing-container">
           Sorting Options 
          <label>Sort By:</label>
          <select>
            <option value="price">Price</option>
            <option value="rating">Rating</option>
            <option value="date">Date Posted</option>
          </select>
        </div>
         Information */}
        <p className="listing-info">Showing {searchResults.length} of {searchResults.length} results for: </p>
      </div>
      <div className="listing-results">
        {/* Dummy content */}
        {searchResults.length === 0 ? (
          <div className="no-results-message">
            <p>{placeholderString}</p>
          </div>
        ) : (searchResults.map(result => (
          <div className={`listing-item ${result.course.isDeactivated ? 'listing-deactivated' : ''}`} key={result.subject.subject_id}>
            <div className="profile-info">
              <div>
                <React.Suspense>
                  <img className="profile-picture" src={imageUrls[searchResults.indexOf(result)]} alt="Profile" />
                </React.Suspense>
                <StarRating rating={calcAvg(result.ratings, "rating")} />
              </div>
            </div>
            <div className="listing-course-info">
              <p><span>Subject Name:</span> {result.subject.subject_code} - {result.subject.subject_name}</p>
              <p><span>Tutor Name:</span> {result.user.user_fname} {result.user.user_lname}</p>
              <p><span>CGPA:</span>{result.userEdu.grades}</p>
              <p><span>Grade Obtained:</span> {result.course.grade}</p>
              <p><span>Free Trial:</span> {result.course.free_trial}</p>
              <p><span>Class Mode:</span>{displayOnlineClassMode(result.course.online_enable)} &nbsp; {displayOfflineClassMode(result.course.offline_enable)}</p>
              <p><span>RM/Hr:</span> {result.course.price.$numberDecimal}</p>
            </div>
            <div className="actions">
              <a
                onClick={() => openEditModal(result)}
                className="action-button edit-listing-button"
              >
                Edit <br /> Lesson
              </a>
              <a
                onClick={() => handleDeactivateCourse(result)}
                className="action-button edit-listing-button"
              >
                {result.course.isDeactivated ? (
                  <>
                    Reactivate <br /> Lesson
                  </>
                ) : (
                  <>
                    Deactivate <br /> Lesson
                  </>
                )}
              </a>
              <a
                onClick={() => openPasswordModal(result.course)}
                className="action-button delete-listing-button"
              >
                Delete <br /> Lesson
              </a>
            </div>
          </div>
        )))}
      </div>
      {/* Create Course Modal */}
      {showModal && (
        <CourseModal
          handleUpdate={handleCreateCourse}
          user={userData}
          closeModal={closeModal}
          object={null}
        />
      )}
      {showEditModal && (
        <CourseModal
          handleUpdate={handleCreateCourse}
          user={userData}
          closeModal={closeEditModal}
          object={selectedCourse}
        />
      )}
      {showPasswordModal && (
        <PasswordModal
          handleUpdate={handleDeleteWithVerification}
          closeModal={closePasswordModal}
        />
      )}
    </div>
  );
};

export default Listings;