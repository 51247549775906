import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

const initialState = {
    isLoggedIn: false,
    user: null,
};

const userReducer = (state = null, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return action.payload;
      case 'LOGOUT':
        return null;
      default:
        return state;
    }
  };
  
  const isLoggedInReducer = (state = false, action) => {
    switch (action.type) {
      case 'LOGIN_SUCCESS':
        return true;
      case 'LOGOUT':
        return false;
      default:
        return state;
    }
  };
  
  export const rootReducer = combineReducers({
    user: userReducer,
    isLoggedIn: isLoggedInReducer,
  });
  


const persistConfig = {
    key: 'root',
    storage,
    whitelist: ['isLoggedIn', 'user'], // state properties to persist
};

const persistedReducer = persistReducer(persistConfig, rootReducer);

export default persistedReducer;
