import './../../stylesheet/user/account.css'
import './../../stylesheet/button.css'
import React, { useState, useEffect } from 'react';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

const PasswordModal = ({ handleUpdate, closeModal }) => {
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message

  const handleUpdateWithVerification = () => {
    // Pass the entered password for verification
    handleUpdate(password);
  };
  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>Please enter your password to confirm the update:</p>
        {error && <div className="error-message">{error}</div>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
        />
        <button onClick={handleUpdateWithVerification}>Update</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

export default PasswordModal;