import './../stylesheet/user.css';
import './../stylesheet/user/account.css'
import './../stylesheet/user/create.css'
import './../stylesheet/user/history.css'
import './../stylesheet/user/listing.css'
import './../stylesheet/user/messages.css'
import './../stylesheet/user/reviews.css'
import './../stylesheet/button.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './../../images/logo.png';
import { useSelector } from 'react-redux';
import Account from './user/account.js'
import Listings from './user/listing.js'
import StarRatingInput from './starRatingsInput.js';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';

const calcSum = (array, prop) => {
  if (array.length == 0) {
    return 0;
  }
  let total = 0;
  for (let i = 0; i < array.length; i++) {
    total += array[i][prop];
  }
  return total;
}

const ConfirmationModal = ({ handleDelete, closeModal }) => {
  const handleConfirmDelete = () => {
    handleDelete();
    closeModal();
  };

  return (
    <div className="confirmation-modal">
      <div className="modal-content">
        <h2>Are you sure you want to delete this schedule?</h2>
        <div className="modal-buttons">
          <button className="confirm-delete-button" onClick={handleConfirmDelete}>
            Yes
          </button>
          <button className="cancel-delete-button" onClick={closeModal}>
            No
          </button>
        </div>
      </div>
    </div>
  );
};

const ViewSessions = ({ handleAction, closeModal, course, user, user_status }) => {
  console.log(user);
  const [showConfirmationModal, setShowConfirmationModal] = useState(false);
  const [selectedSession, setSelectedSession] = useState(null);
  const [currentCourse, setCourse] = useState(course);

  const openConfirmationModal = (session) => {
    setSelectedSession(session)
    setShowConfirmationModal(true);
  };

  const displayConfirmationStatus = (boolean) => {
    if (boolean) {
      return "Confirmed";
    } else {
      return "Pending";
    };
  };

  const handleDelete = async () => {
    try {
      // Make API call to delete the session
      await axios.post('http://localhost:5000/api/deleteschedule', {
        session_id: selectedSession.session_id
      });

      // Update the sessions array in the state by filtering out the deleted session
      setCourse(prevCourse => ({
        ...prevCourse,
        sessions: prevCourse.sessions.filter(s => s._id !== selectedSession._id)
      }));

      // Optionally, perform any additional actions upon successful deletion
      handleAction();
    } catch (error) {
      console.error('Error deleting schedule:', error);
      // Handle error appropriately
    }
  };

  const handleConfirm = async (session) => {
    try {
      // Make API call to confirm the session
      await axios.post('http://localhost:5000/api/confirmschedule', {
        session: session,
        course: course,
        tutee: user
      });

      // Update the sessions array in the state to mark the session as confirmed
      setCourse(prevCourse => ({
        ...prevCourse,
        sessions: prevCourse.sessions.map(s => s._id === session._id ? { ...s, isConfirmed: true } : s)
      }));

      // Optionally, perform any additional actions upon successful confirmation
      handleAction();
    } catch (error) {
      console.error('Error confirming schedule:', error);
      // Handle error appropriately
    }
  };


  return (
    <div className="review-container">
      <div className="review-content">
        <h2>Schedule Details for {currentCourse.subject.subject_id} - {currentCourse.subject.subject_name}</h2>
        {currentCourse.sessions.length === 0 ? (
          <div className="no-results-message">
            <p>No class schedules made yet.</p>
          </div>
        ) : (
          <table>
            <thead>
              <tr>
                <th>Date</th>
                <th>Start Time</th>
                <th>End Time</th>
                <th>Status</th>
                <th>Actions</th>
              </tr>
            </thead>
            <tbody>
              {(currentCourse.sessions.map(session => (
                <tr key={session.session_id}>
                  <td>{new Date(session.session_date).toLocaleDateString()}</td>
                  <td>{session.start_time}</td>
                  <td>{session.end_time}</td>
                  <td>{displayConfirmationStatus(session.isConfirmed)}</td>
                  <td>
                    {user_status === 'tutor' ? (
                      !session.isCompleted && (
                        <button className='action-button delete-button' onClick={() => openConfirmationModal(session)}>Delete</button>
                      )
                    ) : (
                      !session.isConfirmed && (
                        <button className='action-button confirm-button' onClick={() => handleConfirm(session)}>Confirm</button>
                      )
                    )}
                  </td>
                </tr>
              )))}
            </tbody>
          </table>
        )}
        <div className='actions'>
          <div className='button-groups'>
            <button className='action-button cancel-button' onClick={closeModal}>Cancel</button>
          </div>
        </div>
      </div>
      {showConfirmationModal && (
        <ConfirmationModal handleDelete={handleDelete} closeModal={() => setShowConfirmationModal(false)} />
      )}
    </div>
  );
}

const ScheduleModal = ({ handleUpdate, closeModal, course, courseimg, user }) => {
  const [date, setDate] = useState('');
  const [startTime, setStartTime] = useState('');
  const [duration, setDuration] = useState(0);
  const [error, setError] = useState(null); // Added state to hold error message
  const [imageUrl, setImageUrl] = useState(logo)

  useEffect(() => {
    const fetchImage = async () => {

      if (courseimg) {
        try {
          const imageUrl = `http://localhost:5000/files/${course.tutee.user_picture}`;
          const response = await fetch(imageUrl);
          if (response.ok) {
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          } else {
            console.error('Error fetching image:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      } else {
        setImageUrl(logo);
      };
    };
    fetchImage();
  }, []);

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  const handleUpdateWithVerification = async () => {
    // Pass the entered password for verification

    if (!date || !startTime || !duration) {
      setError('Please fill in all fields.');
      return;
    }

    // Check if the date is in the past
    const currentDate = new Date();
    const selectedDate = new Date(date);
    if (selectedDate < currentDate) {
      setError('Selected date must be tomorrow onwards.');
      return;
    }

    const durationInHours = parseInt(duration);
    if (durationInHours > (course.booking.class_hour - calcSum(course.sessions, "duration"))) {
      setError('Duration cannot exceed booked hours.');
      return;
    }

    // Calculate end time
    const startTimeInMinutes = parseInt(startTime.split(':')[0]) * 60 + parseInt(startTime.split(':')[1]);
    let endTimeInMinutes = startTimeInMinutes + durationInHours * 60;

    // Adjust end time if it exceeds 24 hours
    if (endTimeInMinutes >= 24 * 60) {
      endTimeInMinutes -= 24 * 60; // Subtract 24 hours
      selectedDate.setDate(selectedDate.getDate() + 1); // Move to the next day
    }

    const endTimeHours = Math.floor(endTimeInMinutes / 60);
    const endTimeMinutes = endTimeInMinutes % 60;
    const endTime = `${endTimeHours.toString().padStart(2, '0')}:${endTimeMinutes.toString().padStart(2, '0')}`;

    // Clear error state
    setError('');

    try {
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/saveschedule/', {
        date: date,
        startTime: startTime,
        endTime: endTime,
        duration: duration,
        course: course,
        tutor: user
      });
      // Assuming the server responds with the user data
      if (response.status >= 200 && response.status < 300) {

        handleUpdate();

      } else {
        // Display an error message based on the response status
        console.error('verification unsuccessful');
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
    }

  };
  return (
    <div className="review-container">
      <div className="review-content">
        <div className="review-info">
          <React.Suspense>
            <img className="profile-picture" src={imageUrl} alt="Profile" />
            <span>Tutee: {course.tutee.user_fname} {course.tutee.user_lname}</span>
          </React.Suspense>
        </div>
        <div className="listing-course-info">
          <p><span>Subject Name:</span> {course.subject.subject_code} - {course.subject.subject_name}</p>

          <p><span>Duration Booked:</span> {course.booking.class_hour} hours</p>
          <p><span>Free Trial Mins:</span> {course.course.free_trial}</p>
          <p><span>Class Mode:</span>{displayOnlineClassMode(course.course.online_enable)} &nbsp; {displayOfflineClassMode(course.course.offline_enable)}</p>
        </div>
        <h2>Please set your desired date and time for the class schedule:</h2>
        {error && <div className="error-message">{error}</div>}
        <div className='schedule-date'>
          <label htmlFor="classDate">Class Date:</label>
          <input
            type="date"
            id="classDate"
            name="classDate"
            onChange={(e) => setDate(e.target.value)}
          />
        </div>
        <div className='schedule-times'>
          <label htmlFor="classStartTime">Start Time:</label>
          <input
            type="time"
            id="classStartTime"
            name="classStartTime"
            onChange={(e) => setStartTime(e.target.value)}
          />
          <label htmlFor="duration">Duration (hours):</label>
          <input
            type="number"
            id="duration"
            min="1"
            value={duration}
            onChange={(e) => setDuration(e.target.value)}
          />
        </div>
        <div className='button-groups'>
          <button className='action-button cancel-button' onClick={closeModal}>Cancel</button>
          <button className='action-button' onClick={handleUpdateWithVerification}>Submit Schedule</button>
        </div>
      </div>
    </div>
  )
}

const Classes = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedClass, setSelectedClass] = useState(null);

  const location = useLocation();
  const [placeholderString, setPlaceholderString] = useState('Searching results..');
  const [showScheduleModal, setShowScheduleModal] = useState(false);
  const [showViewScheduleModal, setShowViewScheduleModal] = useState(false);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          setUserData(userData);

          try {
            // Make a POST request to the server with the user's email
            const userResponse = await axios.post(`http://localhost:5000/api/getclass/`, {
              user_ID: userData.user_ID
            });
            // Assuming the server responds with the user data

            setSearchResults(userResponse.data);

            setPlaceholderString("No courses have been booked yet.")

          } catch (error) {
            console.error('Error fetching user data:', error);
          }

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(searchResults.map(async (result) => {
        if (result.tutee && result.tutee.user_picture !== '') {
          try {
            const imageUrl = `http://localhost:5000/files/${result.tutee.user_picture}`;
            const response = await fetch(imageUrl);
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              console.error('Error fetching image:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          return logo;
        }
      }));
      setImageUrls(urls);
    };

    fetchImages();
  }, [searchResults]);

  const openScheduleModal = (object) => {
    setSelectedClass(object);
    setShowScheduleModal(true);
  };

  // Function to handle closing the modal
  const closeScheduleModal = () => {
    setShowScheduleModal(false);
  };


  const openChat = async (object) => {
    try {
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/getChat/', {
        class: object.booking
      });

      if (response.data) {
        // Redirect the user to the chat room with the obtained chatId
        navigate(`/message/${response.data.chatId._id}`);
      } else {
        console.error('Chat ID not found in response');
      }

    } catch (error) {
      console.error('Chat error occured:', error);
    }
  }

  function refreshPage() {
    window.location.reload(false);
  }

  // Function to handle submitting the course creation form
  const handleCreateSchedule = () => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    closeScheduleModal();
    refreshPage();
  };

  const handleUpdateSchedule = () => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    closeSchedule();
    refreshPage();
  };

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  const openSchedule = (object) => {
    setSelectedClass(object);
    setShowViewScheduleModal(true);
  }

  const closeSchedule = () => {
    setShowViewScheduleModal(false);
  }

  return (
    <div className="classes-page">
      <div className='sorting-and-info-classes-container'>
        {/*<div className="sorting-classes-container">
           Sorting Options 
          <label>Sort By:</label>
          <select>
            <option value="price">Price</option>
            <option value="rating">Rating</option>
            <option value="date">Date Posted</option>
          </select>
        </div>
         Information */}
        <p className="classes-info">Showing {searchResults.length} of {searchResults.length} results for: </p>
      </div>
      <div className="classes-results">
        {/* Dummy content */}
        {searchResults.length === 0 ? (
          <div className="no-results-message">
            <p>{placeholderString}</p>
          </div>
        ) : (searchResults.map(result => (
          <div className="classes-item" key={result.subject.subject_id}>
            <div className="profile-info">
              <div>
                <React.Suspense>
                  <img className="profile-picture" src={imageUrls[searchResults.indexOf(result)]} alt="Profile" />
                  <span>{result.tutee.user_fname} {result.tutee.user_lname}</span>
                </React.Suspense>
              </div>
            </div>
            <div className="classes-course-info">
              <p><span>Subject Name:</span> {result.subject.subject_code} - {result.subject.subject_name}</p>

              <p><span>Duration Booked:</span> {result.booking.class_hour} hours</p>
              <p><span>Amount Paid:</span> RM{result.payment.payment_amount.$numberDecimal}</p>
              <p><span>Free Trial Mins:</span> {result.course.free_trial}</p>
              <p><span>Class Mode:</span>{displayOnlineClassMode(result.course.online_enable)} &nbsp;
                {displayOfflineClassMode(result.course.offline_enable)}</p>
            </div>
            <div className="actions">
              {calcSum(result.sessions, "duration") < result.booking.class_hour ? (
                <a
                  onClick={() => openScheduleModal(result)}
                  className="action-button edit-listing-button"
                >
                  Schedule <br /> Course
                </a>
              ) : (
                <div className="no-hours-message">
                  <a>
                    <button className="action-button disabled-button" disabled title="Maximum class duration fulfilled">Schedule Course</button>
                  </a>
                </div>
              )}
              <a
                onClick={() => openChat(result)}
                className="action-button edit-listing-button"
              >
                Chat with <br /> Tutee
              </a>
              <a
                onClick={() => openSchedule(result)}
                className="action-button edit-listing-button"
              >
                View <br /> Schedule
              </a>
            </div>
          </div>
        )))}
      </div>
      {showScheduleModal && (
        <ScheduleModal handleUpdate={handleCreateSchedule} closeModal={closeScheduleModal} course={selectedClass}
          courseimg={selectedClass.tutee.user_picture} user={userData} />
      )}
      {showViewScheduleModal && (
        <ViewSessions handleAction={handleUpdateSchedule} closeModal={closeSchedule} course={selectedClass} user={userData} user_status='tutor' />
      )}
    </div>
  );
};

const ReviewModal = ({ handleUpdate, closeModal, course, courseimg, user }) => {
  const [review, setReview] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message
  const [imageUrl, setImageUrl] = useState(logo)
  const [rating, setRating] = useState(0);

  useEffect(() => {
    const fetchImage = async () => {

      if (courseimg) {
        try {
          const imageUrl = `http://localhost:5000/files/${course.user.user_picture}`;
          const response = await fetch(imageUrl);
          if (response.ok) {
            const blob = await response.blob();
            const objectUrl = URL.createObjectURL(blob);
            setImageUrl(objectUrl);
          } else {
            console.error('Error fetching image:', response.statusText);
          }
        } catch (error) {
          console.error('Error fetching image:', error);
        }
      } else {
        setImageUrl(logo);
      };
    };
    fetchImage();
  }, []);

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  const handleUpdateWithVerification = async () => {
    // Pass the entered password for verification

    if (rating == 0) {
      setError("Please give a valid rating.")
      return;
    }

    try {
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/savereview/', {
        rating: rating,
        review: review,
        payment_id: course.payment.payment_id,
        tutor_id: course.course.user_ID,
        subject_id: course.course.subject_id,
        tutee_id: user.user_ID
      });
      // Assuming the server responds with the user data
      if (response.status >= 200 && response.status < 300) {

        handleUpdate(review);

      } else {
        // Display an error message based on the response status
        console.error('verification unsuccessful');
      }

    } catch (error) {
      console.error('Error fetching user data:', error);
    }

  };
  return (
    <div className="review-container">
      <div className="review-content">
        <div className="review-info">
          <React.Suspense>
            <img className="profile-picture" src={imageUrl} alt="Profile" />
            <span>Tutor: {course.user.user_fname} {course.user.user_lname}</span>
          </React.Suspense>
        </div>
        <div className="listing-course-info">
          <p><span>Subject Name:</span> {course.subject.subject_code} - {course.subject.subject_name}</p>

          <p><span>Duration Booked:</span> {course.booking.class_hour} hours</p>
          <p><span>Amount Paid:</span> RM{course.payment.payment_amount.$numberDecimal}</p>
          <p><span>Free Trial Mins:</span> {course.course.free_trial}</p>
          <p><span>Class Mode:</span>{displayOnlineClassMode(course.course.online_enable)} &nbsp; {displayOfflineClassMode(course.course.offline_enable)}</p>
          <p><span>Class Status:</span> {course.booking.class_status}</p>
        </div>
        <h2>Please rate & write down your review for the course:</h2>
        {error && <div className="error-message">{error}</div>}
        <div className='rating-box'>
          <StarRatingInput className='rating-input' onChange={setRating} />
        </div>
        <textarea
          className='review-text'
          value={review}
          onChange={(e) => setReview(e.target.value)}
          placeholder="Enter your review here."
        />
        <div className='button-groups'>
          <button className='action-button cancel-button' onClick={closeModal}>Cancel</button>
          <button className='action-button' onClick={handleUpdateWithVerification}>Submit Review</button>
        </div>
      </div>
    </div>
  );
};

const Booking = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [userData, setUserData] = useState({});
  const [selectedCourse, setSelectedCourse] = useState(null);

  const location = useLocation();
  const [placeholderString, setPlaceholderString] = useState('Searching results..');
  const [showReviewModal, setShowReviewModal] = useState(false);
  const [showViewScheduleModal, setShowViewScheduleModal] = useState(false);

  const user = useSelector((state) => state.user);
  const navigate = useNavigate();

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          setUserData(userData);

          try {
            // Make a POST request to the server with the user's email
            const userResponse = await axios.post(`http://localhost:5000/api/getbooking/`, {
              user_ID: userData.user_ID
            });
            // Assuming the server responds with the user data

            setSearchResults(userResponse.data);

            setPlaceholderString("No courses booked yet. Book one and start learning now!")

          } catch (error) {
            console.error('Error fetching user data:', error);
          }

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(searchResults.map(async (result) => {
        if (result.user && result.user.user_picture !== '') {
          try {
            const imageUrl = `http://localhost:5000/files/${result.user.user_picture}`;
            const response = await fetch(imageUrl);
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              console.error('Error fetching image:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          return logo;
        }
      }));
      setImageUrls(urls);
    };

    fetchImages();
  }, [searchResults]);

  function refreshPage() {
    window.location.reload(false);
  }

  // Function to handle submitting the course creation form
  const handleCreateReview = () => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    closeReviewModal();
    refreshPage();
  };

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  const openReviewModal = (object) => {
    setSelectedCourse(object);
    setShowReviewModal(true);
  };

  // Function to handle closing the modal
  const closeReviewModal = () => {
    setShowReviewModal(false);
  };

  const handleUpdateSchedule = () => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    closeSchedule();
    refreshPage();
  };

  const openSchedule = (object) => {
    setSelectedCourse(object);
    setShowViewScheduleModal(true);
  }

  const closeSchedule = () => {
    setShowViewScheduleModal(false);
  }

  const openChat = async (object) => {
    console.log(object.booking);
    try {
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/getChat/', {
        class: object.booking
      });

      if (response.data) {
        // Redirect the user to the chat room with the obtained chatId
        console.log(response.data.chatId)
        navigate(`/message/${response.data.chatId._id}`);
      } else {
        console.error('Chat ID not found in response');
      }

    } catch (error) {
      console.error('Chat error occured:', error);
    }
  }

  return (
    <div className="classes-page">
      <div className='sorting-and-info-classes-container'>
        {/*
        <div className="sorting-classes-container">
          Sorting Options
          <label>Sort By:</label>
          <select>
            <option value="price">Price</option>
            <option value="rating">Rating</option>
            <option value="date">Date Posted</option>
          </select>
        </div>
        Information */}
        
        <p className="classes-info">Showing {searchResults.length} of {searchResults.length} results for: </p>
      </div>
      <div className="classes-results">
        {/* Dummy content */}
        {searchResults.length === 0 ? (
          <div className="no-results-message">
            <p>{placeholderString}</p>
          </div>
        ) : (searchResults.map(result => (
          <div className={result.user.isBanned ? "classes-item banned" : "classes-item"} key={result.subject.subject_id}>
            <div className="profile-info">
              <div>
                <React.Suspense>
                  <img className="profile-picture" src={imageUrls[searchResults.indexOf(result)]} alt="Profile" />
                  <span>{result.user.user_fname} {result.user.user_lname}</span>
                </React.Suspense>
              </div>
            </div>
            <div className="classes-course-info">
              <p><span>Subject Name:</span> {result.subject.subject_code} - {result.subject.subject_name}</p>

              <p><span>Duration Booked:</span> {result.booking.class_hour} hours</p>
              <p><span>Amount Paid:</span> RM{result.payment.payment_amount.$numberDecimal}</p>
              <p><span>Free Trial Mins:</span> {result.course.free_trial}</p>
              <p><span>Class Mode:</span>{displayOnlineClassMode(result.course.online_enable)} &nbsp; {displayOfflineClassMode(result.course.offline_enable)}</p>
            </div>
            <div className="actions">
              {!result.booking.isReviewed && (
                <a
                  onClick={() => openReviewModal(result)}
                  className="action-button edit-listing-button"
                >
                  Review Course
                </a>
              )}
              <a
                onClick={() => openChat(result)}
                className="action-button edit-listing-button"
              >
                Chat with <br /> Tutor
              </a>
              <a
                onClick={() => openSchedule(result)}
                className="action-button edit-listing-button"
              >
                View <br /> Schedule
              </a>
            </div>
          </div>
        )))}
      </div>
      {showReviewModal && (
        <ReviewModal handleUpdate={handleCreateReview} closeModal={closeReviewModal} course={selectedCourse} courseimg={selectedCourse.user.user_picture} user={userData} />
      )}
      {showViewScheduleModal && (
        <ViewSessions handleAction={handleUpdateSchedule} closeModal={closeSchedule} course={selectedCourse} user={userData} user_status='tutee' />
      )}
    </div>
  );
};

const Report = () => {
  const [reportType, setReportType] = useState(null);
  const [reportContents, setReportContents] = useState(null);
  const [supportingURL, setSupportingURL] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [userData, setUserData] = useState({});

  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          setUserData(userData);

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes

  const handleSubmit = async () => {
    if (!reportType || !reportContents) {
      setErrorMessage('Please fill in all required fields.');
      return;
    }

    try {
      const response = await axios.post('http://localhost:5000/api/report', {
        user_ID: userData.user_ID,
        report_type: reportType,
        report_content: reportContents,
        report_url: supportingURL
      });

      console.log(response.data); // Assuming the backend returns some confirmation data

      // Clear the form and reset error message
      setReportType(null);
      setReportContents(null);
      setSupportingURL(null);
      setErrorMessage('');
      
      // Display success message or redirect the user
      alert('Report submitted successfully!');
    } catch (error) {
      console.error('Error submitting report:', error);
      // Handle error (e.g., display error message to user)
      setErrorMessage('Failed to submit report. Please try again later.');
    }
  };

  return (
    <div className="report-container">
      <h2 className="report-heading">Submit a Report</h2>
      <form className="report-form" onSubmit={handleSubmit}>
        <div className="form-group">
          <label htmlFor="reportType">*Report Type:</label>
          <select
            className="report-type"
            id="reportType"
            value={reportType}
            onChange={(e) => setReportType(e.target.value)}
            required
          >
            <option value="">Select Report Type</option>
            <option value="Spam">Spam</option>
            <option value="Harassment">Harassment</option>
            <option value="Abuse">Abuse</option>
            {/* Add more report types as needed */}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="reportContents">*Report Contents:</label>
          <textarea
            className="report-contents"
            id="reportContents"
            value={reportContents}
            onChange={(e) => setReportContents(e.target.value)}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="supportingURL">Supporting URL: &#40;Optional&#41;</label>
          <input
            type="url"
            className="supporting-url"
            id="supportingURL"
            value={supportingURL}
            onChange={(e) => setSupportingURL(e.target.value)}
          />
        </div>
        {errorMessage && <p className="error-message">{errorMessage}</p>}
        <button type="submit" className="action-button submit-btn">Submit Report</button>
      </form>
    </div>
  );
};

const User = () => {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || 'Account');
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    // Check if the user is already logged in
    // Assuming isLoggedIn and setIsLoggedIn are passed as props
    if (!isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setActiveTab(tab || 'Account');
  }, [tab]);

  // Other logic for different tabs
  return (
    <div className="profile-page">
      <div className='profile-settings'>
        <div className="tab-container">
          {/* Tabs Selection */}
          <a href='/user/account' onClick={() => setActiveTab('account')} className={activeTab === 'account' ? 'active' : ''}>Account</a><br />
          <a href='/user/classes' onClick={() => setActiveTab('classes')} className={activeTab === 'classes' ? 'active' : ''}>My Classes</a><br />
          <a href='/user/course-listings' onClick={() => setActiveTab('course-listings')} className={activeTab === 'course-listings' ? 'active' : ''}>Course Listings</a><br />
          <a href='/user/booking-history' onClick={() => setActiveTab('booking-history')} className={activeTab === 'booking-history' ? 'active' : ''}>Booking History</a><br/>
          <a href='/user/report' onClick={() => setActiveTab('report')} className={activeTab === 'report' ? 'active' : ''}>Report a Problem</a>
        </div>
        <div className="tab-window">
          {/* Tabs Display */}
          {activeTab === 'account' && <Account />}
          {activeTab === 'classes' && <Classes />}
          {activeTab === 'course-listings' && <Listings />}
          {activeTab === 'booking-history' && <Booking />}
          {activeTab === 'report' && <Report />}
        </div>
      </div>
    </div>
  );
};

export default User;