import './../stylesheet/Register.css';
import './../stylesheet/button.css';
import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import ReCAPTCHA from "react-google-recaptcha";
import student from './../../images/Join us today.png';
import axios from 'axios';

const ResetPassword = () => {
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    // Check if the user is already logged in
    // Assuming isLoggedIn and setIsLoggedIn are passed as props
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState('');
  const [birthDate, setBirthDate] = useState('');
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [confirmEmail, setConfirmEmail] = useState('');
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Ensure this line is here 
  const [error, setError] = useState(null); // Added state to hold error message
  const [success, setSuccess] = useState(false); // Added state for success message

  const handleSignUp = () => {
    if (!firstName || !lastName || !email || !confirmEmail || !password || !confirmPassword || !gender || !birthDate || !phone || !address) {
      setError('Please fill in all required fields.');
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    // Check if email and confirmEmail match
    if (email !== confirmEmail) {
      setError('Emails do not match');
      return;
    }

    // Check if reCAPTCHA is filled
    if (!recaptchaValue) {
      console.log('Please complete the reCAPTCHA');
      return;
    }

    if (!emailRegex.test(email)) {
      setError('Invalid email format');
      return;
    }

    if (!passwordRegex.test(password)) {
      setError('Invalid password format');
      return;
    }
    const newUser = {
      user_ID: 'placeholder',
      user_fname: firstName,
      user_lname: lastName,
      user_email: email,
      password: password,
      user_gender: gender,           // Include gender
      dob: birthDate,     // Include birth date
      user_phone: phone,             // Include phone number
      user_address: address          // Include address
      // Add other user properties here
    };

    // Make a POST request to your backend to save the user in the database
    axios.post('http://localhost:5000/api/users', newUser)
      .then(response => {
        console.log('User registered successfully:', response.data);
        // Display success message
        setSuccess(true);
        openModal(); // Show the success modal
        // Clear error state
        setError(null);
      })
      .catch(error => {
        console.error('Error registering:', error);

        if (error.response) {
          // The request was made and the server responded with a status code
          // that falls out of the range of 2xx
          if (error.response.status === 409) {
            // HTTP status code 409 indicates conflict, meaning the resource already exists
            setError('This email is already registered. Please use a different email.');
          } else {
            setError('Registration failed. Please try again.');
          }
        } else if (error.name === 'MongoServerError') {
          // Check if it's a MongoDB duplicate key error
          if (error.code === 11000) {
            setError('This email is already registered. Please use a different email.');
          } else {
            setError('Registration failed. Please try again.');
          }
        } else if (error.isAxiosError && error.response?.status === 500) {
          // Axios error with a 500 status code, indicating a server error
          setError('Registration failed. Please try again.');
        } else if (error.request) {
          // The request was made but no response was received
          setError('No response from the server. Please try again later.');
        } else {
          // Something happened in setting up the request that triggered an Error
          setError('An unexpected error occurred. Please try again.');
        }

        // Clear success state
        setSuccess(false);
      });
  };

  // Regex for email validation
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

  // Regex for password validation (minimum eight characters, at least one uppercase letter, one lowercase letter, and one number)
  const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

  // Call this function when you want to show the success message
  function openModal() {
    const modal = document.querySelector('.modal-container');
    modal.classList.add('active');

    // Automatically close the modal after 3000 milliseconds (3 seconds)
    setTimeout(() => {
      modal.classList.add('fadeOut');
      modal.classList.remove('active');
      setTimeout(() => {
        modal.classList.remove('fadeOut');
      }, 500); // Adjust this duration to match the CSS animation duration
    }, 3000);
  }

  return (
    <div className="signup-page">
      <img src={student} alt="university students sitting" className="background-image" />
      <div className="signup-container">
        <h2>Sign Up for IntraTutor</h2>
        <form>
          <div className="input-group">
            <label>First Name:</label>
            <input
              type="text"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>Last Name:</label>
            <input
              type="text"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>Gender:</label>
            <select
              value={gender}
              onChange={(e) => setGender(e.target.value)}
              required
            >
              <option value="">Select</option>
              <option value="M">Male</option>
              <option value="F">Female</option>
              <option value="O">Other</option>
            </select>
          </div>

          <div className="input-group">
            <label>Birth Date:</label>
            <input
              type="date"
              value={birthDate}
              onChange={(e) => setBirthDate(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>Phone Number:</label>
            <input
              type="tel"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>Address:</label>
            <input
              type="text"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>

          <div className="input-group">
            <label>Email:</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              pattern={emailRegex}
              required
            />
          </div>

          <div className="input-group">
            <label>Confirm Email:</label>
            <input
              type="email"
              value={confirmEmail}
              onChange={(e) => setConfirmEmail(e.target.value)}
              pattern={emailRegex}
              required
            />
          </div>

          <div className="input-group">
            <label>Password:</label>
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              pattern={passwordRegex}
              title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number"
              required
            />
          </div>

          <div className="input-group">
            <label>Confirm Password:</label>
            <input
              type="password"
              value={confirmPassword}
              onChange={(e) => setConfirmPassword(e.target.value)}
              pattern={passwordRegex}
              title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number"
              required
            />
          </div>

          {/* Display error message */}
          {error && <div className="error-message">{error}</div>}

          {/* Add reCAPTCHA here */}
          <ReCAPTCHA className='g-recaptcha'
            sitekey="6LdfbH4pAAAAAJE_i1y0JskkrfYbcj0S3W5uedHv" // Replace with your actual reCAPTCHA site key
            onChange={(value) => setRecaptchaValue(value)}
          />

          <button className='action-button' type="button" onClick={handleSignUp}>
            Sign Up
          </button>
        </form>

        <p>
          Already have an account? <a href="/login">Login here</a>.
        </p>
      </div>
      {/* Display success message */}
      <div class="modal-container">
        <div class="success-message modal-content">
          <p>Registration successful! A verification email has been sent to your account.</p>
        </div>
      </div>
    </div>
  );
};

export default ResetPassword;