import '../stylesheet/user/messages.css'
// messages.css

/* Add your styles for the messages component here */

// Messages.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import { faPaperPlane } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import logo from './../../images/logo.png';
import { useSelector } from 'react-redux';
import { io } from 'socket.io-client';
import { useNavigate, useParams } from 'react-router-dom';

const socket = io('http://localhost:5001');

const Messages = () => {
    const [userData, setUserData] = useState({});
    const [chats, setChats] = useState([]);
    const [dummyChat, setDummyChats] = useState([]);
    const [selectedChat, setSelectedChat] = useState(null);
    const [newMessage, setNewMessage] = useState('');
    const [tooltipContent, setTooltipContent] = useState('');
    const [imageUrls, setImageUrls] = useState([]);

    const user = useSelector((state) => state.user);
    const messagesEndRef = useRef(null);
    const { chatId } = useParams(); // Get the chatId parameter from the URL
    const [tempChatId, setTempChatId] = useState(chatId);

    const navigate = useNavigate();
    const isLoggedIn = useSelector((state) => state.isLoggedIn);
    useEffect(() => {
        // Check if the user is already logged in
        // Assuming isLoggedIn and setIsLoggedIn are passed as props
        if (!isLoggedIn) {
            navigate('/');
        }
    }, [isLoggedIn]);

    function sortChatsByLastMessageDate(chats) {
        // Sort the chats array based on the date of the last message
        chats.sort((chat1, chat2) => {
            const lastMessageDate1 = new Date(chat1.chat.messages[chat1.chat.messages.length - 1].timestamp);
            const lastMessageDate2 = new Date(chat2.chat.messages[chat2.chat.messages.length - 1].timestamp);

            // Compare the dates
            if (lastMessageDate1 < lastMessageDate2) {
                return 1; // Chat 1 comes after Chat 2
            } else if (lastMessageDate1 > lastMessageDate2) {
                return -1; // Chat 1 comes before Chat 2
            } else {
                return 0; // Dates are equal
            }
        });

        return chats;
    }

    useEffect(() => {
        // Set selectedChat based on the chatId parameter
        if (chatId && chats.length > 0 && imageUrls.length > 0) {
            // Find the chat with the matching chatId
            const selected = chats.find(chat => chat.chat._id === chatId);
            if (selected) {
                // Set the found chat as the selectedChat
                setSelectedChat(selected);
                setTimeout(() => {
                    scrollToBottom();
                }, 100); // Adjust the delay time as needed
                window.history.pushState({}, '', `/message/${tempChatId}`);
                setTempChatId('');
            } else {
                console.error(`Chat with ID ${tempChatId} not found.`);
            }
        }
    }, [dummyChat]);

    useEffect(() => {
        const fetchUserData = async () => {
            if (user && user !== 'admin') {
                try {
                    // Make a POST request to the server with the user's email
                    const response = await axios.post('http://localhost:5000/api/account/', {
                        email: user
                    });
                    // Assuming the server responds with the user data
                    const userData = response.data.user;

                    setUserData(userData);

                    try {
                        // Make a POST request to the server with the user's email
                        const userResponse = await axios.get(`http://localhost:5000/api/chat/${userData.user_ID}`);
                        // Assuming the server responds with the user data
                        const sortedChats = sortChatsByLastMessageDate(userResponse.data)
                        await setChats(sortedChats);
                        setDummyChats(sortedChats);
                    } catch (error) {
                        console.error('Error fetching user data:', error);
                    }

                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            } else if (user === 'admin') {
                const adminObj = {
                    user_ID: 'admin',
                    user_fname: 'admin'
                }

                setUserData(adminObj);

                try {
                    // Make a POST request to the server with the user's email
                    const userResponse = await axios.get(`http://localhost:5000/api/chat/${'admin'}`);
                    // Assuming the server responds with the user data
                    console.log(userResponse.data)
                    const sortedChats = sortChatsByLastMessageDate(userResponse.data)
                    await setChats(sortedChats);
                    setDummyChats(sortedChats);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        }

        fetchUserData();
    }, [user]);

    useEffect(() => {
        const fetchImages = async () => {
            const urls = await Promise.all(chats.map(async (result) => {
                let tutorImageUrl;
                if (user !== 'admin') {
                    tutorImageUrl = result.user && result.user.user_picture !== '' ?
                        fetchImage(result.user.user_picture) : logo;
                } else {
                    tutorImageUrl = logo;
                }
                const tuteeImageUrl = result.tutee && result.tutee.user_picture !== '' ?
                    fetchImage(result.tutee.user_picture) : logo;
                return Promise.all([tutorImageUrl, tuteeImageUrl]);
            }));
            setImageUrls(urls);
        };

        const fetchImage = async (picture) => {
            try {
                const imageUrl = `http://localhost:5000/files/${picture}`;
                const response = await fetch(imageUrl);
                if (response.ok) {
                    const blob = await response.blob();
                    return URL.createObjectURL(blob);
                } else {
                    console.error('Error fetching image:', response.statusText);
                    return logo;
                }
            } catch (error) {
                console.error('Error fetching image:', error);
                return logo;
            }
        };

        fetchImages();
    }, [chats]);

    useEffect(() => {
        scrollToBottom();
    }, [chats]);

    const sendMessage = async () => {
        try {
            // Emit a 'sendMessage' event to the server with the new message data
            socket.emit('sendMessage', { chatId: selectedChat, content: newMessage, user: userData });
            // Clear the message input field
            setNewMessage('');
        } catch (error) {
            console.error('Error sending message:', error);
        }
    };

    useEffect(() => {
        const handleMessageReceived = async (updatedChat) => {
            if (selectedChat && selectedChat.chat && updatedChat) {
                // Find the index of the updated chat in the chats array
                const index = chats.findIndex(chat => (
                    chat.chat.tutor_id === updatedChat.tutor_id &&
                    chat.chat.subject_id === updatedChat.subject_id &&
                    chat.chat.tutee_id === updatedChat.tutee_id
                ));
                if (index !== -1) {
                    // Update the chat with the new message data
                    const updatedChats = [...chats];
                    updatedChats[index].chat = updatedChat;
                    await setChats(sortChatsByLastMessageDate(updatedChats));
                    scrollToBottom();

                    // Check if the updated chat is the currently selected and viewed chat room
                    if (selectedChat.chat.tutor_id === updatedChat.tutor_id &&
                        selectedChat.chat.subject_id === updatedChat.subject_id &&
                        selectedChat.chat.tutee_id === updatedChat.tutee_id) {
                        // Emit an event to the backend to mark the chat room as read
                        socket.emit('viewedChatRoom', selectedChat, userData);
                    }
                }
            }
        };

        // Register the event listener only once
        socket.on('messageReceived', handleMessageReceived);

        // Cleanup the event listener when the component unmounts
        return () => {
            socket.off('messageReceived', handleMessageReceived);
        };
    }, [selectedChat, chats]);

    useEffect(() => {
        const handleReadReceived = async (updatedChat) => {
            console.log("This is reached hopefully");
            if (selectedChat && selectedChat.chat && updatedChat) {
                // Find the index of the updated chat in the chats array
                const index = chats.findIndex(chat => (
                    chat.chat.tutor_id === updatedChat.tutor_id &&
                    chat.chat.subject_id === updatedChat.subject_id &&
                    chat.chat.tutee_id === updatedChat.tutee_id
                ));
                if (index !== -1) {
                    // Update the chat with the new message data
                    const updatedChats = [...chats];
                    updatedChats[index].chat = updatedChat;
                    await setChats(sortChatsByLastMessageDate(updatedChats));
                    scrollToBottom()
                }
            }
        };

        // Register the event listener only once
        socket.on('readSet', handleReadReceived);

        // Cleanup the event listener when the component unmounts
        return () => {
            socket.off('readSet', handleReadReceived);
        };
    }, [selectedChat, chats]);

    const scrollToBottom = () => {
        messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' });
    };

    const openChatRoom = async (chat) => {
        window.history.pushState({}, '', `/message/${chat.chat._id}`);
        await setSelectedChat(chat)
        socket.emit('viewedChatRoom', chat, userData); // Assuming _id is the unique identifier of the chat room
        scrollToBottom();
    }

    const handleMouseEnter = (timestamp) => {
        setTooltipContent(timestamp);
    };

    const handleMouseLeave = () => {
        setTooltipContent('');
    };

    const renderMessages = () => {
        if (!selectedChat) return null; // Return null if no chat is selected
        const chat = selectedChat; // Find the selected chat object
        if (!chat) return null; // Return null if the selected chat is not found
        return chat.chat.messages.map((message) => (
            <div
                key={message._id}
                className={`message ${message.sender_id === userData.user_ID ? 'user-message' : 'other-message'}`}
                onMouseEnter={() => handleMouseEnter(new Date(message.timestamp).toLocaleString())}
                onMouseLeave={handleMouseLeave}
            >
                {message.sender_id !== userData.user_ID && (
                    <img
                        src={imageUrls[chats.indexOf(chat)][message.sender_id === chat.chat.tutee_id ? 1 : 0]}
                        alt="User Avatar"
                        className="chat-avatar"
                        onMouseEnter={() => handleMouseEnter(message.sender_id)}
                        onMouseLeave={handleMouseLeave}
                    />
                )}
                <div className="message-content">{message.content}</div>
                {message.sender_id === userData.user_ID && chat && imageUrls[chats.indexOf(chat)] && (
                    <img
                        src={imageUrls[chats.indexOf(chat)][message.sender_id === chat.chat.tutee_id ? 1 : 0]}
                        alt="User Avatar"
                        className="chat-avatar"
                        onMouseEnter={() => handleMouseEnter(message.sender_id)}
                        onMouseLeave={handleMouseLeave}
                    />
                )}
                {tooltipContent && (
                    <div className="tooltip">
                        {tooltipContent}
                    </div>
                )}
            </div>
        ));
    };

    const handleKeyPress = (event) => {
        if (event.key === 'Enter') {
            sendMessage();
        }
    };

    return (
        <div className="messages-container">
            <div className="chats-list">
                {imageUrls.length > 0 && chats.map((chat) => (
                    <div>
                        {chat.chat.tutee_id === userData.user_ID ? (
                            <div
                                key={chat.chat.tutee_id + chat.chat.user + chat.chat.subject_id}
                                className={`chat ${chat === selectedChat ? 'active' : ''} ${chat.chat.tutee_unread ? 'unread' : ''} ${chat.user.isBanned ? 'banned' : ''}`}
                                onClick={() => openChatRoom(chat)}
                            >
                                <div className="chat-info">
                                    <img src={imageUrls[chats.indexOf(chat)][0]} alt="User Avatar" className="avatar" />
                                    <div className="chat-details">
                                        {chat.user.isBanned ? (
                                            <div className="chat-name">BANNED USER {chat.user.user_fname} {chat.user.user_lname}</div>
                                        ) : (
                                            <div className="chat-name">{chat.user.user_fname} {chat.user.user_lname}</div>
                                        )}
                                        <div className="last-message">
                                            <div className="subject-details">
                                                <span className="subject-code">{chat.subject.subject_code}</span>
                                                <span className="subject-name">{chat.subject.subject_name}</span>
                                            </div>
                                            {(chat.chat.messages[chat.chat.messages.length - 1]).sender_id === userData.user_ID ? (
                                                <span>{chat.tutee.user_fname}: </span>
                                            ) : (
                                                <span>{chat.user.user_fname}: </span>
                                            )}
                                            {(chat.chat.messages[chat.chat.messages.length - 1]).content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div
                                key={chat.chat.tutee_id + chat.chat.user + chat.chat.subject_id}
                                className={`chat ${chat === selectedChat ? 'active' : ''} ${chat.chat.tutor_unread ? 'unread' : ''} ${chat.tutee.isBanned ? 'banned' : ''}`}
                                onClick={() => openChatRoom(chat)}
                            >
                                <div className="chat-info">
                                    <img src={imageUrls[chats.indexOf(chat)][1]} alt="User Avatar" className="avatar" />
                                    <div className="chat-details">
                                        {chat.tutee.isBanned ? (
                                            <div className="chat-name">BANNED USER {chat.tutee.user_fname} {chat.tutee.user_lname}</div>
                                        ) : (
                                            <div className="chat-name">{chat.tutee.user_fname} {chat.tutee.user_lname}</div>
                                        )}

                                        <div className="last-message">
                                            <div className="subject-details">
                                                <span className="subject-code">{chat.subject.subject_code}</span>
                                                <span className="subject-name">{chat.subject.subject_name}</span>
                                            </div>
                                            {(chat.chat.messages[chat.chat.messages.length - 1]).sender_id === userData.user_ID ? (
                                                <span>{chat.user.user_fname}: </span>
                                            ) : (
                                                <span>{chat.tutee.user_fname}: </span>
                                            )}
                                            {(chat.chat.messages[chat.chat.messages.length - 1]).content}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )}

                    </div>
                ))}
            </div>
            <div className="messages-list">
                <div className='messages-box'>
                    {renderMessages()}
                    <div ref={messagesEndRef} />
                </div>
                <div className="messages-input">
                    <input
                        type="text"
                        placeholder="Type your message..."
                        value={newMessage}
                        onChange={(e) => setNewMessage(e.target.value)}
                        onKeyPress={handleKeyPress}
                    />
                    <button onClick={sendMessage}>
                        <FontAwesomeIcon icon={faPaperPlane} />
                    </button>
                </div>
            </div>
        </div>
    );
};

export default Messages;