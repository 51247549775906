import './../stylesheet/search.css';
import './../stylesheet/button.css'
import React, { useState, useEffect } from 'react';
import logo from './../../images/logo.png';
import StarRating from './starRatings.js';
import StarRatingInput from './starRatingsInput.js';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Search = () => {
    const [userData, setUserData] = useState({});
    const [educationData, setEducationData] = useState([]);
    const [sortBy, setSortBy] = useState('');
    const [searchResults, setSearchResults] = useState([]);
    const [spareResults, setSpareResults] = useState([]);
    const [finalResults, setFinalResults] = useState([]);
    const [imageUrls, setImageUrls] = useState([]);

    // Filtering criteria state
    const [showOwnUniversities, setShowOwnUniversities] = useState(false);
    const [showOwnProgrammes, setShowOwnProgrammes] = useState(false);
    const [minRating, setMinRating] = useState(0);
    const [maxRating, setMaxRating] = useState(5);
    const [tutorGender, setTutorGender] = useState(null); // 'male', 'female', null for all
    const [classMode, setClassMode] = useState(null); // 'online', 'offline', null for all
    const [showActive, setActive] = useState(false);

    const user = useSelector((state) => state.user);
    const [minPrice, setMinPrice] = useState(0);
    const [maxPrice, setMaxPrice] = useState(100);
    const location = useLocation();
    const query = new URLSearchParams(location.search).get('query');
    const [placeholderString, setPlaceholderString] = useState('Searching results..');

    // Update filtered results whenever filtering criteria change
    useEffect(() => {

        const applyFilters = () => {
            let filtered = spareResults;
            // Filter by own universities
            if (showOwnUniversities && user) {
                // Get an array of university_ids from educationData
                const userUniversityIds = educationData.map(edu => edu.programme.university_id);

                // Filter the results to include only the ones matching any of the user's university_ids
                filtered = filtered.filter(result => {
                    // Access the university_id of the result's education data
                    const universityId = result.result.university.university_id;
                    // Check if the university_id matches any of the user's university_ids
                    return userUniversityIds.includes(universityId);
                });
            }

            // Filter by own programmes
            if (showOwnProgrammes && user) {
                // Get an array of programme_ids from educationData
                const userProgrammeIds = educationData.map(edu => edu.programme.programme_id);

                // Filter the results to include only the ones matching any of the user's programme_ids
                filtered = filtered.filter(result => {
                    // Access the programme_id of the result's education data
                    const programmeId = result.result.userEdu.programme_id;
                    // Check if the programme_id matches any of the user's programme_ids
                    return userProgrammeIds.includes(programmeId);
                });
            }

            // Filter by rating range
            filtered = filtered.filter(result => calcAvg(result.result.ratings, "rating") >= minRating && calcAvg(result.result.ratings, "rating") <= maxRating);
            //
            // Filter by price range
            filtered = filtered.filter(result => result.result.course.price.$numberDecimal >= minPrice && result.result.course.price.$numberDecimal <= maxPrice);

            // Filter by tutor gender
            if (tutorGender) {
            }

            // Filter by class mode
            if (classMode) {
                if (classMode == "online")
                    filtered = filtered.filter(result => result.result.course.online_enable === true);
                else if (classMode == "offline")
                    filtered = filtered.filter(result => result.result.course.offline_enable === true);
            }

            if (showActive) {
                console.log(showActive)
                if (showActive == "online")
                    filtered = filtered.filter(result => result.result.course.isDeactivated === false);
                else if (showActive == "offline")
                    filtered = filtered.filter(result => result.result.course.isDeactivated === true);
            }

            if (filtered.length === 0 && spareResults.length !== 0) {
                setPlaceholderString("No results found. Try a different filtering option.")
            }

            setFinalResults(filtered);

        };

        applyFilters();
    }, [showOwnUniversities, showOwnProgrammes, minRating, maxRating, minPrice, maxPrice, tutorGender, classMode, showActive]);

    // Other code...

    const sortingOptions = {
        price1: (a, b) => a.result.course.price.$numberDecimal - b.result.course.price.$numberDecimal,
        price2: (a, b) => b.result.course.price.$numberDecimal - a.result.course.price.$numberDecimal,
        rating1: (a, b) => calcAvg(a.result.ratings, "rating") - calcAvg(b.result.ratings, "rating"),
        rating2: (a, b) => calcAvg(b.result.ratings, "rating") - calcAvg(a.result.ratings, "rating"),
        hours1: (a, b) => calcSum(a.result.students, "class_hour") - calcSum(b.result.students, "class_hour"),
        hours2: (a, b) => calcSum(b.result.students, "class_hour") - calcSum(a.result.students, "class_hour"),
        grades1: (a, b) => a.result.userEdu.grades - b.result.userEdu.grades,
        grades2: (a, b) => b.result.userEdu.grades - a.result.userEdu.grades,
        students1: (a, b) => a.result.students.length - b.result.students.length,
        students2: (a, b) => b.result.students.length - a.result.students.length
    };

    // Handle sorting selection change
    const handleSortChange = (e) => {
        setSortBy(e.target.value);
    };

    // Apply sorting logic
    useEffect(() => {
        if (sortBy) {
            const sorted = [...finalResults].sort(sortingOptions[sortBy]);
            setFinalResults(sorted);
        }
    }, [sortBy]);

    const calcAvg = (array, prop) => {
        if (array.length == 0) {
            return 0;
        }
        let total = 0;
        for (let i = 0; i < array.length; i++) {
            total += array[i][prop];
        }
        let avg = total / array.length;
        return avg.toFixed(1);
    }

    const calcSum = (array, prop) => {
        if (array.length == 0) {
            return 0;
        }
        let total = 0;
        for (let i = 0; i < array.length; i++) {
            total += array[i][prop];
        }
        return total;
    }

    useEffect(() => {
        const fetchUserData = async () => {
            if (user) {
                try {
                    // Make a POST request to the server with the user's email
                    const response = await axios.post('http://localhost:5000/api/account/', {
                        email: user
                    });
                    // Assuming the server responds with the user data
                    const userData = response.data.user;
                    // Update your component state with the fetched data
                    setUserData(userData);

                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchUserData();
    }, [user]);  // Run the effect whenever the user changes

    useEffect(() => {
        const fetchEducationData = async () => {
            if (user) {
                try {
                    // Make a POST request to the server with the user's email
                    const response = await axios.post('http://localhost:5000/api/account/', {
                        email: user
                    });
                    // Assuming the server responds with the user data
                    const userData = response.data.user;

                    const eduResponse = await axios.post('http://localhost:5000/api/education/', {
                        user_ID: userData.user_ID
                    });
                    const userEduData = eduResponse.data;
                    setEducationData(userEduData);
                } catch (error) {
                    console.error('Error fetching user data:', error);
                }
            }
        };

        fetchEducationData();
    }, [user]);

    useEffect(() => {

        if (query) {
            // Fetch user data based on the user's email
            axios.get(`http://localhost:5000/api/searchRoute/${query}`)
                .then(response => {
                    setSearchResults(response.data);
                    console.log(response.data);
                    if (response.data.length === 0) {
                        setPlaceholderString("No results found for " + query + ". Try a different search query.")
                    }
                })
                .catch(error => {
                    console.error('Error fetching search data:', error);
                });
        }
    }, [query, searchResults]);  // Run the effect whenever the user changes

    useEffect(() => {
        const fetchImages = async () => {
            const urls = await Promise.all(searchResults.map(async (result) => {
                if (result.user && result.user.user_picture !== '') {
                    try {
                        const imageUrl = `http://localhost:5000/files/${result.user.user_picture}`;
                        const response = await fetch(imageUrl);
                        if (response.ok) {
                            const blob = await response.blob();
                            const objectUrl = URL.createObjectURL(blob);
                            return objectUrl;
                        } else {
                            console.error('Error fetching image:', response.statusText);
                        }
                    } catch (error) {
                        console.error('Error fetching image:', error);
                    }
                } else {
                    return logo;
                }
            }));
            if (imageUrls.length == 0) {
                setImageUrls(urls);
            }
        };

        fetchImages();
    }, [searchResults]);

    useEffect(() => {
        const combinedResults = searchResults.map((result) => {
            return { result, imageUrl: imageUrls[searchResults.indexOf(result)] };
        });
        setFinalResults(combinedResults);
        setSpareResults(combinedResults);
        setMinPrice(Math.min(...combinedResults.map(result => parseFloat(result.result.course.price.$numberDecimal))));
        setMaxPrice(Math.max(...combinedResults.map(result => parseFloat(result.result.course.price.$numberDecimal))));
    }, [imageUrls]);

    return (
        <div className="search-page">
            <div className='sorting-and-info-container'>
                <div className="sorting-container">
                    {/* Sorting Options */}
                    <label>Sort By:</label>
                    <select onChange={handleSortChange}>
                        <option value="">Select Sorting Attribute</option>
                        <option value="price1">Price: Low to High</option>
                        <option value="price2">Price: High to Low</option>
                        <option value="rating1">Rating: Low to High</option>
                        <option value="rating2">Rating: High to Low</option>
                        <option value="hours1">Hours Taught: Low to High</option>
                        <option value="hours2">Hours Taught: High to Low</option>
                        <option value="grades1">Grades: Low to High</option>
                        <option value="grades2">Grades: High to Low</option>
                        <option value="students1">Number of Students: Low to High</option>
                        <option value="students2">Number of Students: High to Low</option>
                    </select>
                </div>
                {/* Information */}
                <p className="results-info">Showing {finalResults.length} of {searchResults.length} results for: </p>
            </div>
            <div className='filtered-results'>
                <div className="filtering-box">
                    <h3>Filter By:</h3>
                    {user && (
                        <>
                    <div>
                        <label>Show Only Own Universities:</label>
                        <input type="checkbox" checked={showOwnUniversities} onChange={() => setShowOwnUniversities(!showOwnUniversities)} />
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div>
                        <label>Show Only Own Programmes:</label>
                        <input type="checkbox" checked={showOwnProgrammes} onChange={() => setShowOwnProgrammes(!showOwnProgrammes)} />
                    </div>
                    <hr className='horizontal-line'></hr>
                        </>
                    )}
                    <div className='rating-box'>
                        <label>Minimum Rating:</label>
                        <StarRatingInput onChange={setMinRating} />
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div className='rating-box'>
                        <label>Maximum Rating:</label>
                        <StarRatingInput onChange={setMaxRating} />
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div>
                        <label>Price Range: <br/></label>
                        <input type="range" 
                        min={(Math.min(...searchResults.map(result => parseFloat(result.course.price.$numberDecimal))))} 
                        max={(Math.max(...searchResults.map(result => parseFloat(result.course.price.$numberDecimal))))} 
                        value={minPrice} onChange={(e) => setMinPrice(e.target.value)} />
                        <span><br/>Minimum: RM{minPrice}<br/></span>
                        <hr className='horizontal-line-price'></hr>
                        <input type="range" 
                        min={(Math.min(...searchResults.map(result => parseFloat(result.course.price.$numberDecimal))))} 
                        max={(Math.max(...searchResults.map(result => parseFloat(result.course.price.$numberDecimal))))} 
                        value={maxPrice} onChange={(e) => setMaxPrice(e.target.value)} />
                        <span><br/>Maximum: RM{maxPrice}</span>
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div>
                        <label>Tutor Gender:</label>
                        <select value={tutorGender} onChange={(e) => setTutorGender(e.target.value)}>
                            <option value="">All</option>
                            <option value="M">Male</option>
                            <option value="F">Female</option>
                        </select>
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div>
                        <label>Class Mode:</label>
                        <select value={classMode} onChange={(e) => setClassMode(e.target.value)}>
                            <option value="">All</option>
                            <option value="online">Online</option>
                            <option value="offline">Offline</option>
                        </select>
                    </div>
                    <hr className='horizontal-line'></hr>
                    <div>
                        <label>Class Active Status:</label>
                        <select value={showActive} onChange={(e) => setActive(e.target.value)}>
                            <option value="">All</option>
                            <option value="online">Active Only</option>
                            <option value="offline">Inactive Only</option>
                        </select>
                    </div>
                </div>
                <div className="search-results">
                    {finalResults.length === 0 ? (
                        <div className="no-results-message">
                            <p>{placeholderString}</p>
                        </div>
                    ) : (finalResults.map(result => (
                        <div className={`result-item ${result.result.course.isDeactivated ? 'result-item-deactivated' : ''}`} key={result.result.subject_id}>
                            <div className="profile-info">
                                <div>
                                    <React.Suspense>
                                        <img className="profile-picture" src={result.imageUrl} alt="Profile" />
                                    </React.Suspense>
                                    <StarRating rating={calcAvg(result.result.ratings, "rating")} />
                                </div>
                            </div>
                            <div className="course-info">
                                <p><span>Subject Name:</span> {result.result.subject.subject_code} - {result.result.subject.subject_name}</p>
                                <p><span>Tutor Name:</span> {result.result.user.user_fname} {result.result.user.user_lname}</p>
                                <p><span>CGPA:</span>{result.result.userEdu.grades}</p>
                                <p><span>Grade Obtained:</span> {result.result.course.grade}</p>
                                <p><span>No. Students:</span> {result.result.students.length}</p>
                                <p><span>Hours Taught:</span> {calcSum(result.result.students, "class_hour")}</p>
                                <p><span>Mode of Class:</span>
                                    {result.result.course.offline_enable && (
                                        <>
                                            &nbsp;Physical&nbsp;
                                        </>
                                    )}
                                    {result.result.course.online_enable && (
                                        <>
                                            &nbsp;Online&nbsp;
                                        </>
                                    )}
                                </p>
                            </div>
                            <div className="pricing">
                                <span className='pricing-rate'>RM/Hr:<br /><span className='price'>{result.result.course.price.$numberDecimal}</span></span>

                                <a
                                    href={`/course?subjectID=${result.result.subject.subject_id}&userID=${result.result.user.user_ID}&programmeID=${result.result.programme.programme_id}&universityID=${result.result.university.university_id}`}
                                    className="action-button book-button"
                                >
                                    View <br /> Course
                                </a>

                            </div>
                        </div>
                    )))}

                </div>
            </div>


            {/* Display Search Results (to be implemented later) */}
        </div>
    );
};

export default Search;
