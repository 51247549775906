import './App.css';
import './navigation.css';
import './bodyFont.css';
import { BrowserRouter as Router, Route, Routes, Link } from 'react-router-dom';
import React, { useState, useEffect, useRef } from 'react';
import { Provider } from 'react-redux';
import { store, persistor } from './redux/store';
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import MainPage from './pages/javascript/MainPage';  // Your main page
import LoginPage from './pages/javascript/Login';
import RegistrationPage from './pages/javascript/Register';
import Search from './pages/javascript/search';
import Course from './pages/javascript/course';
import Payment from './pages/javascript/payment';
import User from './pages/javascript/user';
import ResetPassword from './pages/javascript/resetPassword';
import Messages from './pages/javascript/message';
import Admin from './admin/admin';
import logo from './images/logo.png';
import { logout } from './redux/actions';
import axios from 'axios';

function App() {

  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  const user = useSelector((state) => state.user);

  const [isDropdownVisible, setIsDropdownVisible] = useState(false);
  const [messagesNotification, setMessagesNotification] = useState(false);
  const [shouldShowNavbar, setShouldShowNavbar] = useState(true);
  const [imgURL, setImgURL] = useState(logo);
  const lastScrollTop = useRef(0);

  const dropdownRef = useRef(null);
  const profileBtnRef = useRef(null);

  const handleProfileButtonClick = () => {
    setIsDropdownVisible((prev) => !prev);
  };

  const closeDropdown = () => {
    setIsDropdownVisible(false);
  };;

  const handleClickOutside = (event) => {
    if (
      isDropdownVisible &&
      dropdownRef.current &&
      profileBtnRef.current &&
      !dropdownRef.current.contains(event.target) &&
      !profileBtnRef.current.contains(event.target)
    ) {
      closeDropdown();
    }
  };

  const handleScroll = () => {
    const currentScrollTop = window.scrollY;

    setShouldShowNavbar(currentScrollTop <= 0 || currentScrollTop < lastScrollTop.current);
    lastScrollTop.current = currentScrollTop;

    closeDropdown();
  };

  const handleLinkClick = (event) => {
    if (!isDropdownVisible) {
      event.preventDefault(); // Prevent the default behavior
      // Optionally, you can add logic to show the dropdown here
    }
  };


  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    window.addEventListener('scroll', handleScroll);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
      window.removeEventListener('scroll', handleScroll);
    };
  }, [isDropdownVisible]);

  useEffect(() => {
    const hasUnreadMessages = (chats, userId) => {
      for (const chat of chats) {
          if ((userId === chat.chat.tutee_id && chat.chat.tutee_unread) ||
              (userId === chat.chat.tutor_id && chat.chat.tutor_unread)) {
              return true;
          }
      }
      return false;
  };

    const fetchUserData = async () => {
      if (user && user !== 'admin') {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          if (userData.isBanned) {
            dispatch(logout());
          }

          if (userData.user_picture != '') {
            fetchAndDisplayImage(userData.user_picture);
          }

          try {
            // Make a POST request to the server with the user's email
            const userResponse = await axios.get(`http://localhost:5000/api/chat/${userData.user_ID}`);
            // Assuming the server responds with the user data
            const hasUnread = hasUnreadMessages(userResponse.data, userData.user_ID)
            setMessagesNotification(hasUnread);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }  else if (user === 'admin') {

        try {
            // Make a POST request to the server with the user's email
            const userResponse = await axios.get(`http://localhost:5000/api/chat/${'admin'}`);
            const hasUnread = hasUnreadMessages(userResponse.data, 'admin')
            setMessagesNotification(hasUnread);
        } catch (error) {
            console.error('Error fetching user data:', error);
        }
    }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes
  
  const fetchAndDisplayImage = async (filename) => {
    try {
      // Fetch the image from the server
      const imageUrl = `http://localhost:5000/files/${filename}`;
      const response = await fetch(imageUrl);
  
      if (response.ok) {
        // Convert the response to blob and create an object URL
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);
  
        setImgURL(objectUrl);
  
      } else {
        console.error('Error fetching image:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  return (
    <Provider store={store}>
      <Router>
        <div className={`topnav ${shouldShowNavbar ? '' : 'hidden'}`}>
          <div className="logo-container">
            <Link to="/" id="home-btn">
              <img src={logo} alt="Logo" className="logo" />
              <span href="#" className="logo-text">
                IntraTutor
              </span>
            </Link>
          </div>
          <div className="search-bar">
            <form className='search-form' action="/search" method="GET">
              <input className='search-input' type="text" placeholder="Search..." name="query" />
              <button className="search-button" type="submit">
                <i className="fa fa-search"></i>
                {/* This is the Font Awesome search icon */}
              </button>
            </form>
          </div>
          <div className="user-links">
            {isLoggedIn && (
              // For logged in users
              <>
                {<a href="/" onClick={() => dispatch(logout())} id="logout-btn">Logout</a>}

                <a href='/message' id="inbox-btn">
                  <i className="fa fa-envelope"></i>
                  {messagesNotification && <span className="notification-badge"></span>}
                </a>
                {user != 'admin' ? (
                  <>
                <a href="#" className="profile-btn" ref={profileBtnRef} onClick={handleProfileButtonClick}>
                  <img className="profile-pic" src={imgURL} alt="Profile" />
                </a>

                <div className={`profile-dropdown ${isDropdownVisible ? 'visible' : ''}`} ref={dropdownRef}>
                  {/* Dropdown content */}
                  <a href="/user/account" onClick={handleLinkClick}>Account Settings</a><br />
                  <a href="/user/classes" onClick={handleLinkClick}>My Classes</a><br />
                  <a href="/user/course-listings" onClick={handleLinkClick}>Create a Course</a><br />
                  <a href="/user/booking-history" onClick={handleLinkClick} >Booking History</a>
                </div>
                </>
                ) : (
                  <a href="/admin" >Admin Panel</a>
                )}
              </>
            )}
            {!isLoggedIn && (
              // For unlogged in users
              <>
                <Link to="/login" id="login-btn">
                  Login
                </Link>
                <Link to="/register" id="signup-btn">
                  Sign Up
                </Link>
              </>
            )}
          </div>
        </div>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/login" element={<LoginPage />} />
          <Route path="/register" element={<RegistrationPage />} />
          <Route path='/search' element={<Search />}></Route>
          <Route path='/course' element={<Course />}></Route>
          <Route path='/payment' element={<Payment />}></Route>
          <Route path='/user/:tab' element={<User />}></Route>
          <Route path='/user' element={<User />}></Route>
          <Route path='/reset' element={<ResetPassword />}></Route>
          <Route path="/message/:chatId" element={<Messages />}></Route>
          <Route path='/message' element={<Messages />}></Route>
          <Route path='/admin' element={<Admin />}></Route>
        </Routes>
      </Router>
    </Provider>
  );
}

export default App;
