import './../stylesheet/Login.css';
import './../stylesheet/button.css';
import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { loginSuccess } from './../../redux/actions';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import ReCAPTCHA from "react-google-recaptcha";
import signUp from './../../images/signUp.jpg';
import axios from 'axios';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

const NewPasswordModal = ({ handleUpdate, closeModal }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message

  const handleUpdateWithVerification = () => {

    // Pass the entered password for verification
    if (!password || !confirmPassword) {
      setError('Please fill in all required fields.');
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!passwordRegex.test(password)) {
      setError('Invalid password format. Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number, no symbol');
      return;
    }
    handleUpdate(password);
  };
  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>Please enter your new password:</p>
        {error && <div className="error-message">{error}</div>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number, no symbol"
          required
        />
        <p>Please enter your confirm new password:</p>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          pattern={passwordRegex}
          placeholder="Enter your confirm password"
          title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number, no symbol"
          required
        />
        <button onClick={handleUpdateWithVerification}>Update</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

const VerificationModal = ({ closeModal, onSuccess }) => {
  const [email, setEmail] = useState('');
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [dispayCodeSent, setDisplayCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let timer = null;
    if (codeSent || countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
        setCodeSent(false);
      }, 1000);
    } else if (countdown === 0) {
      setCodeSent(false);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [codeSent, countdown]);

  const handleSendCode = async () => {
    // Simulate sending the verification code
    setDisplayCodeSent(true);
    setCodeSent(true);
    setCountdown(45);

    try {
      const userData = await axios.post('http://localhost:5000/api/compareemail', {
        user_email: email
      });
      console.log(userData)
      const response = await axios.post('http://localhost:5000/api/verifyemail', {
        user_ID: userData.data.user_ID,
        user_email: email
      });
      if (response.status === 201) {
        setError('');
        setCodeSent(true);
        setCountdown(45);
      } else {
        setError('Failed to send verification code. Please try again.');
      }
    } catch (error) {
      setError('Failed to send verification code. Please try again.');
    }
  };

  const handleVerify = async () => {
    try {
      const userData = await axios.post('http://localhost:5000/api/compareemail', {
        user_email: email
      });
      const response = await axios.post('http://localhost:5000/api/updateemail', {
        userID: userData.data.user_ID,
        verificationCode: verificationCode,
      });
      if (response.status === 200) {
        // Handle successful verification
        onSuccess(email);
      } else {
        setError('Error verifying code. Please try again.');
      }
    } catch (error) {
      setError('Error verifying code. Please try again.');
    }
  };

  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>Please enter your email:</p>
        <input
          type="email"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Enter your email"
          required
        />

        <button disabled={codeSent || countdown > 0} onClick={handleSendCode}>
          {codeSent ? 'Code Sent' : countdown > 0 ? `Resend Code (${countdown}s)` : 'Send Verification Code'}
        </button>

        {dispayCodeSent && (
          <p>An email with a verification code has been sent to your email.</p>
        )}

        <input
          type="text"
          placeholder="Enter verification code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
          required
        />

        {error && <div className="error-message">{error}</div>}

        <button onClick={handleVerify}>Submit</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

const Login = () => {
  const navigate = useNavigate();
  const [forgotPassword, setForgotPassword] = useState(false);
  const [showVerificationModal, setShowVerificationModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);

  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    // Check if the user is already logged in
    // Assuming isLoggedIn and setIsLoggedIn are passed as props
    if (isLoggedIn) {
      navigate('/');
    }
  }, [isLoggedIn]);

  const dispatch = useDispatch();

  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [recaptchaValue, setRecaptchaValue] = useState(null); // Ensure this line is here
  const [error, setError] = useState(null); // New state for login error

  const handleLogin = async () => {
    // Your login logic here
    if (recaptchaValue) {
    } else {
      // Handle case where reCAPTCHA is not validated
      console.error("reCAPTCHA validation failed");
      const errorMessage = "reCAPTCHA validation failed";
      setError(errorMessage);
      return;
    }

    if (!email || !password) {
      const errorMessage = 'Please enter both email and password.';
      setError(errorMessage);
      return;
    }

    // Perform login API call using Axios
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: email,
        password: password
      });

      // Check if the response is successful (status code 200-299)
      if (response.status == 200) {
        const data = response.data;
        // Redirect to the main page after successful login

        // Display a success message (you can replace this with your actual logic)
        alert('Login successful!');
        const user = { user_email: email };
        dispatch(loginSuccess(user));
        navigate('/');
        setError(null); // Reset error state
      } else if (response.status == 201) {
        const data = response.data;
        // Redirect to the main page after successful login

        // Display a success message (you can replace this with your actual logic)
        alert('Admin Login successful!');
        const user = { user_email: 'admin' };
        dispatch(loginSuccess(user));
        navigate('/admin');
        setError(null); // Reset error state
      } else if (response.status == 202) {
        const errorMessage = "You're banned from IntraTutor. If you think this is a mistake, please email us for further assitance.";
        setError(errorMessage)
      }
      else {
        // Display an error message based on the response status
        const errorMessage = `Login failed. Status: ${response.status}`;
        setError(errorMessage);
      }
    } catch (error) {
      console.error('Error during login:', error);
      const errorMessage = 'Invalid login email or password. Please try again.';
      setError(errorMessage);
    }

  };

  const handleForgotPassword = async () => {
    setShowVerificationModal(true);
  };

  const handleVerificationClose = () => {
    setShowVerificationModal(false);
  };

  const handleVerificationSuccess = (email) => {
    setEmail(email);
    setShowVerificationModal(false);
    setShowNewPasswordModal(true);
  };

  const handleNewPasswordClose = () => {
    setShowNewPasswordModal(false);
  };

  const handlePasswordReset = async (password) => {
    handleNewPasswordClose();
    try {
      const userData = await axios.post('http://localhost:5000/api/compareemail', {
        user_email: email
      });
      const response = await axios.post('http://localhost:5000/api/reset/', {
        user_ID: userData.data.user_ID,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        const modal = document.querySelector('.modal-container');
        modal.classList.add('active');

        // Automatically close the modal after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          modal.classList.add('fadeOut');
          modal.classList.remove('active');
          setTimeout(() => {
            modal.classList.remove('fadeOut');
          }, 500); // Adjust this duration to match the CSS animation duration
        }, 3000);

        setShowNewPasswordModal(false);

      } else {
        // Display an error message based on the response status
        console.error('Update unsuccessful');
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
  }

  return (
    <div className="login-page">
      <img src={signUp} alt="university students sitting" className="background-image" />
      <div className="login-container">
        <h2>Login to IntraTutor</h2>
        <form>
          <label>Email:</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            required
          />

          <label>Password:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />

          {/* Forgot Password Section */}
          <a onClick={handleForgotPassword}>
            Forgot Password?
          </a>

          {/* reCAPTCHA */}
          <ReCAPTCHA className='g-recaptcha'
            sitekey="6LdfbH4pAAAAAJE_i1y0JskkrfYbcj0S3W5uedHv" // Replace with your actual reCAPTCHA site key
            onChange={(value) => setRecaptchaValue(value)}
          />

          {/* Display error message */}
          {error && <div className="error-message">{error}</div>}

          <button className='action-button' type="button" onClick={handleLogin}>
            Login
          </button>
        </form>
        <p className="register-link">
          New to IntraTutor? <a href="/register">Register here</a>.
        </p>
      </div>
      {/* New Password Modal */}
      {showNewPasswordModal && (
        <NewPasswordModal handleUpdate={handlePasswordReset} onClose={handleNewPasswordClose} />
      )}
      {/* Verification Modal */}
      {showVerificationModal && (
        <VerificationModal
          closeModal={handleVerificationClose}
          onSuccess={handleVerificationSuccess}
        />
      )}
      {/* Display success message */}
      <div className="modal-container">
        <div className="success-message modal-content">
          <p>Password Reset successful! You can now login with your new password.</p>
        </div>
      </div>
    </div>
  );
};

export default Login;
