import './../pages/stylesheet/user.css';
import './../pages/stylesheet/button.css'
import logo from './../images/logo.png';
import './admin.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import { faEdit, faTrash, faEnvelope, faLink, faBan, faUndo, faList } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import StarRating from "./../pages/javascript/starRatings";

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const calcSum = (array, prop) => {
  if (array.length == 0) {
    return 0;
  }
  let total = 0;
  for (let i = 0; i < array.length; i++) {
    total += array[i][prop];
  }
  return total;
}

const Reports = () => {
  const [reports, setReports] = useState([])
  const [showResolveModal, setShowResolveModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedReport, setSelectedReport] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`http://localhost:5000/api/admin/report/`);

        // Update state with the new data
        setReports(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, []);

  const handleResolve = (report) => {
    setSelectedReport(report);
    setShowResolveModal(true);
  };

  const handleDelete = (report) => {
    setSelectedReport(report);
    setShowDeleteModal(true);
  };

  const handleChat = (report) => {
    setSelectedReport(report);
    setShowChatModal(true);
  };

  const handleCloseResolveModal = () => setShowResolveModal(false);
  const handleCloseDeleteModal = () => setShowDeleteModal(false);
  const handleCloseChatModal = () => setShowChatModal(false);

  const handleConfirmResolve = async () => {
    try {
      const response = await axios.post(`http://localhost:5000/api/admin/resolvereport/`, {
        report_id: selectedReport.report.report_id
      });

      // Update state with the new data
      setShowResolveModal(false);
      if (response.status === 200) {
        setReports(prevReports => {
          const updatedReports = prevReports.map(report => {
            if (report.report.report_id === selectedReport.report.report_id) {
              // Return a new object with updated payment status
              return {
                ...report,
                report: {
                  ...report.report,
                  status: 'Resolved'
                }
              };
            }
            return report;
          });
          return updatedReports;
        });
      }

    } catch (error) {
      console.error('Error fetching course data:', error);
    }

  };

  const handleConfirmDelete = async () => {
    // Implement logic to delete the report
    try {
      const response = await axios.post(`http://localhost:5000/api/admin/delreport/`, {
        report_id: selectedReport.report.report_id
      });

      // Update state with the new data
      setShowDeleteModal(false);
      if (response.status === 200) {
        setReports(prevReports => {
          // Filter out the selected report from the list of reports
          const updatedReports = prevReports.filter(report => report.report.report_id !== selectedReport.report.report_id);
          return updatedReports;
        });
      }

    } catch (error) {
      console.error('Error fetching course data:', error);
    }
  };

  const handleConfirmChat = async () => {
    // Implement logic to initiate chat with the reporting user
    try {
      const chatObj = {
        subject_id: 'admin',
        tutee_id: selectedReport.reporter.user_ID,
        tutor_id: 'admin'
      }
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/admin/chat/', {
        class: chatObj
      });

      if (response.data) {
        // Redirect the user to the chat room with the obtained chatId
        setShowChatModal(false);

        navigate(`/message/${response.data.chatId._id}`);
      } else {
        console.error('Chat ID not found in response');
      }

    } catch (error) {
      console.error('Chat error occured:', error);
    }

  };

  return (
    <div id="reports-tab" className="tab-content">
      <h2>User Reports</h2>
      <table id="reports-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Reporter</th>
            <th>Type</th>
            <th>Contents</th>
            <th>URL</th>
            <th>Date</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="reports-body">
          {reports.map(report => (
            <tr key={report.report.report_id}>
              <td>{report.report.report_id}</td>
              <td>{report.reporter.user_fname} {report.reporter.user_lname}</td>
              <td>{report.report.report_type}</td>
              <td className='report-content'>{report.report.report_content}</td>
              <td><a href={report.report.report_url}><FontAwesomeIcon icon={faLink} /></a></td>
              <td>{formatDate(report.report.date)}</td>
              <td>{report.report.status}</td>
              <td className='admin-button-cell'>
                <FontAwesomeIcon className='admin-button' icon={faEdit} title="Resolve" onClick={() => handleResolve(report)} />
                <br />
                <FontAwesomeIcon className='admin-button' icon={faTrash} title="Delete" onClick={() => handleDelete(report)} />
                <br />
                <FontAwesomeIcon className='admin-button' icon={faEnvelope} title="Initiate Chat" onClick={() => handleChat(report)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Resolve Report Modal */}
      {showResolveModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Resolve</h3>
            <p>Are you sure you want to mark this report as resolved?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Reporter</th>
                  <th>Type</th>
                  <th>Contents</th>
                  <th>URL</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedReport.report.report_id}>
                  <td>{selectedReport.report.report_id}</td>
                  <td>{selectedReport.reporter.user_fname} {selectedReport.reporter.user_lname}</td>
                  <td>{selectedReport.report.report_type}</td>
                  <td>{selectedReport.report.report_content}</td>
                  <td><a href={selectedReport.report.report_url}><FontAwesomeIcon icon={faLink} /></a></td>
                  <td>{formatDate(selectedReport.report.date)}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseResolveModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmResolve}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {/* Delete Report Modal */}
      {showDeleteModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to delete this report?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Reporter</th>
                  <th>Type</th>
                  <th>Contents</th>
                  <th>URL</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedReport.report.report_id}>
                  <td>{selectedReport.report.report_id}</td>
                  <td>{selectedReport.reporter.user_fname} {selectedReport.reporter.user_lname}</td>
                  <td>{selectedReport.report.report_type}</td>
                  <td>{selectedReport.report.report_content}</td>
                  <td><a href={selectedReport.report.report_url}><FontAwesomeIcon icon={faLink} /></a></td>
                  <td>{formatDate(selectedReport.report.date)}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseDeleteModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmDelete}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {/* Chat Modal */}
      {showChatModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Initiate Chat</h3>
            <p>Would you like to initiate a chat with the reporting user?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Reporter</th>
                  <th>Type</th>
                  <th>Contents</th>
                  <th>URL</th>
                  <th>Date</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedReport.report.report_id}>
                  <td>{selectedReport.report.report_id}</td>
                  <td>{selectedReport.reporter.user_fname} {selectedReport.reporter.user_lname}</td>
                  <td>{selectedReport.report.report_type}</td>
                  <td>{selectedReport.report.report_content}</td>
                  <td><a href={selectedReport.report.report_url}><FontAwesomeIcon icon={faLink} /></a></td>
                  <td>{formatDate(selectedReport.report.date)}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className="action-button delete-acc-button" onClick={handleCloseChatModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmChat}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Requests = () => {
  const [educationData, setEducationData] = useState([]);
  const [showApproveModal, setShowApproveModal] = useState(false);
  const [showRejectModal, setShowRejectModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedEdu, setSelectedEdu] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`http://localhost:5000/api/admin/pending/`);

        // Update state with the new data
        setEducationData(response.data);

      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, []);

  const handleApprove = (edu) => {
    setSelectedEdu(edu);
    setShowApproveModal(true);
  };

  const handleReject = (edu) => {
    setSelectedEdu(edu);
    setShowRejectModal(true);
  };

  const handleChat = (edu) => {
    setSelectedEdu(edu);
    setShowChatModal(true);
  };

  const handleCloseApproveModal = () => setShowApproveModal(false);
  const handleCloseRejectModal = () => setShowRejectModal(false);
  const handleCloseChatModal = () => setShowChatModal(false);

  const handleConfirmApprove = async () => {
    try {
      const response = await axios.post(`http://localhost:5000/api/admin/approvepending/`, {
        edu: selectedEdu.userEdu
      });

      // Update state with the new data
      setShowApproveModal(false);
      if (response.status === 200) {
        setEducationData(prevEduData => {
          // Filter out the selected report from the list of reports
          const updatedEduData = prevEduData.filter(edu => edu.userEdu.user_ID !== selectedEdu.userEdu.user_ID &&
            edu.userEdu.programme_id !== selectedEdu.userEdu.programme_id);
          return updatedEduData;
        });
      }

    } catch (error) {
      console.error('Error fetching course data:', error);
    }

  };

  const handleConfirmReject = async () => {
    try {
      const response = await axios.post(`http://localhost:5000/api/admin/rejpending/`, {
        edu: selectedEdu.userEdu
      });

      // Update state with the new data
      setShowRejectModal(false);
      if (response.status === 200) {
        setEducationData(prevEduData => {
          // Filter out the selected report from the list of reports
          const updatedEduData = prevEduData.filter(edu => edu.userEdu.user_ID !== selectedEdu.userEdu.user_ID &&
            edu.userEdu.programme_id !== selectedEdu.userEdu.programme_id);
          return updatedEduData;
        });
      }

    } catch (error) {
      console.error('Error fetching course data:', error);
    }

  };

  const handleConfirmChat = async () => {
    try {
      const chatObj = {
        subject_id: 'admin',
        tutee_id: selectedEdu.user.user_ID,
        tutor_id: 'admin'
      }
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/admin/chat/', {
        class: chatObj
      });

      if (response.data) {
        // Redirect the user to the chat room with the obtained chatId
        setShowChatModal(false);

        navigate(`/message/${response.data.chatId._id}`);
      } else {
        console.error('Chat ID not found in response');
      }

    } catch (error) {
      console.error('Chat error occured:', error);
    }
  };

  return (
    <div id="requests-tab" className="tab-content">
      <h2>Pending Approvals</h2>
      <table id="requests-table" className="requests-table">
        <thead>
          <tr>
            <th>User</th>
            <th>University</th>
            <th>Programme</th>
            <th>Grades</th>
            <th>Start Date</th>
            <th>End Date</th>
            <th>URL</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="requests-body">
          {educationData.map(edu => (
            <tr key={edu.user.user_ID + edu.programme.programme_id}>
              <td>{edu.user.user_fname} {edu.user.user_lname}</td>
              <td>{edu.university.university_name}</td>
              <td>{edu.programme.programme_name}</td>
              <td>{edu.userEdu.grades}</td>
              <td>{formatDate(edu.userEdu.start_year)}</td>
              <td>{formatDate(edu.userEdu.end_year)}</td>
              <td><a href={edu.userEdu.files_url}><FontAwesomeIcon icon={faLink} /></a></td>
              <td className='admin-button-cell'>
                <FontAwesomeIcon className='admin-button' icon={faEdit} title="Approve" onClick={() => handleApprove(edu)} />
                <br />
                <FontAwesomeIcon className='admin-button' icon={faTrash} title="Reject" onClick={() => handleReject(edu)} />
                <br />
                <FontAwesomeIcon className='admin-button' icon={faEnvelope} title="Initiate Chat" onClick={() => handleChat(edu)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {/* Approve Request Modal */}
      {showApproveModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Resolve</h3>
            <p>Are you sure you want to mark this request as approved?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>University</th>
                  <th>Programme</th>
                  <th>Grades</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedEdu.user.user_ID + selectedEdu.programme.programme_id}>
                  <td>{selectedEdu.user.user_fname} {selectedEdu.user.user_lname}</td>
                  <td>{selectedEdu.university.university_name}</td>
                  <td>{selectedEdu.programme.programme_name}</td>
                  <td>{selectedEdu.userEdu.grades}</td>
                  <td>{formatDate(selectedEdu.userEdu.start_year)}</td>
                  <td>{formatDate(selectedEdu.userEdu.end_year)}</td>
                  <td><a href={selectedEdu.userEdu.files_url}><FontAwesomeIcon icon={faLink} /></a></td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseApproveModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmApprove}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {/* Reject Request Modal */}
      {showRejectModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to reject this request?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>University</th>
                  <th>Programme</th>
                  <th>Grades</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedEdu.user.user_ID + selectedEdu.programme.programme_id}>
                  <td>{selectedEdu.user.user_fname} {selectedEdu.user.user_lname}</td>
                  <td>{selectedEdu.university.university_name}</td>
                  <td>{selectedEdu.programme.programme_name}</td>
                  <td>{selectedEdu.userEdu.grades}</td>
                  <td>{formatDate(selectedEdu.userEdu.start_year)}</td>
                  <td>{formatDate(selectedEdu.userEdu.end_year)}</td>
                  <td><a href={selectedEdu.userEdu.files_url}><FontAwesomeIcon icon={faLink} /></a></td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseRejectModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmReject}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {/* Chat Modal */}
      {showChatModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Initiate Chat</h3>
            <p>Would you like to initiate a chat with the requesting user?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>User</th>
                  <th>University</th>
                  <th>Programme</th>
                  <th>Grades</th>
                  <th>Start Date</th>
                  <th>End Date</th>
                  <th>URL</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedEdu.user.user_ID + selectedEdu.programme.programme_id}>
                  <td>{selectedEdu.user.user_fname} {selectedEdu.user.user_lname}</td>
                  <td>{selectedEdu.university.university_name}</td>
                  <td>{selectedEdu.programme.programme_name}</td>
                  <td>{selectedEdu.userEdu.grades}</td>
                  <td>{formatDate(selectedEdu.userEdu.start_year)}</td>
                  <td>{formatDate(selectedEdu.userEdu.end_year)}</td>
                  <td><a href={selectedEdu.userEdu.files_url}><FontAwesomeIcon icon={faLink} /></a></td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className="action-button delete-acc-button" onClick={handleCloseChatModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmChat}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const SubjectsFormModal = ({ handleUpdate, closeModal, object, programme }) => {
  const [subject, setSubject] = useState('');
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [type, setType] = useState('Core');
  const [error, setError] = useState('');

  console.log(object);

  useEffect(() => {

    const fetchSubject = async () => {
      if (object) {
        setSubject(object.subject_id);
        setName(object.subject_name);
        setCode(object.subject_code);
        setType(object.subject_type);
        setError('');
      }
    };

    fetchSubject();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!name) {

      setError('Please fill in all required fields. At least the university name');
      return;
    }
    // Additional validation logic can be added here

    try {
      let url;
      if (object) {
        url = 'http://localhost:5000/api/admin/editsubject/';
      } else {
        url = 'http://localhost:5000/api/admin/addsubject/';
      }
      const response = await axios.post(url, {
        subject_id: subject,
        subject_name: name,
        subject_code: code,
        subject_type: type,
        programme_id: programme
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        // Reset form fields
        setName('');
        setCode('');
        setType('');
        setError('');

        handleUpdate(response.data);

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  return (
    <div className='create-course-modal'>
      <div className="create-course-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="name">Subject Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="abbreviation">Subject Code:</label>
            <input
              type="text"
              id="abbreviation"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="type">Subject Type:</label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="Core">Core</option>
              <option value="English Proficiency">English Proficiency</option>
              <option value="Others">Others</option>
            </select>
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit</button>
            <button className='action-button cancel-button' type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const SubjectsModal = ({ selectedProgramme, handleClose }) => {
  const [subjects, setSubjects] = useState(selectedProgramme.subjects);
  const [selectedSubject, setSelectedSubject] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEdit = (subject) => {
    setSelectedSubject(subject);
    setShowEditModal(true);
  };

  const handleDelete = (subject) => {
    setSelectedSubject(subject);
    setShowDeleteModal(true);
  };

  const handleCreate = () => {
    setShowCreateModal(true)
  };

  const handleCloseCreateModal = () => setShowCreateModal(false);

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleCreateSubject = (response) => {

    setSubjects(prevSubjects => [...prevSubjects, response]);

    setShowCreateModal(false);
  }

  const handleConfirmEdit = (response) => {

    setSubjects(prevSubjects => {

      const index = prevSubjects.findIndex(subject => subject.subject_id === selectedSubject.subject_id);
      if (index !== -1) {

        const updatedSubjects = [...prevSubjects];

        const updatedSubject = {
          ...prevSubjects[index],
          subject_name: response.subject_name,
          subject_code: response.subject_code,
          subject_type: response.subject_type
        };;

        updatedSubjects[index] = updatedSubject;
        return updatedSubjects;
      } else {
        return prevSubjects;
      }
    });

    setShowEditModal(false);
  };

  const handleConfirmDelete = async () => {
    // Implement logic to mark the report as resolved

    try {
      const response = await axios.post('http://localhost:5000/api/admin/delsubject/', {
        subject_id: selectedSubject.subject_id,
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setSubjects(prevSubjects => {
          // Filter out the selected report from the list of reports
          const updatedSubjects = prevSubjects.filter(subject => subject.subject_id !== selectedSubject.subject_id);
          return updatedSubjects;
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }

    setShowDeleteModal(false);
  };

  return (
    <div className="admin-modal-background">
      <div className="admin-modal">
        <div>
          <a
            className="action-button create-button" onClick={handleCreate}
          >
            Add Subject
          </a>
        </div>
        <div className="admin-modal-buttons">
          <button className="action-button delete-acc-button" onClick={handleClose}>Close</button>
        </div>
        <h3>Subjects for {selectedProgramme.programme.programme_name}</h3>
        <table className='modal-table'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Type</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {subjects.map(subject => (
              <tr key={subject.subject_id}>
                <td>{subject.subject_code}</td>
                <td>{subject.subject_name}</td>
                <td>{subject.subject_type}</td>
                <td>
                  <FontAwesomeIcon className='admin-button' icon={faEdit} title="Edit" onClick={() => handleEdit(subject)} />
                  &nbsp;&nbsp;
                  <FontAwesomeIcon className='admin-button' icon={faTrash} title="Delete" onClick={() => handleDelete(subject)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showCreateModal && (
        <SubjectsFormModal
          handleUpdate={handleCreateSubject}
          closeModal={handleCloseCreateModal}
          object={null}
          programme={selectedProgramme.programme.programme_id}
        />
      )}
      {showEditModal && (
        <SubjectsFormModal
          handleUpdate={handleConfirmEdit}
          closeModal={handleCloseEditModal}
          object={selectedSubject}
          programme={selectedProgramme.programme.programme_id}
        />
      )}
      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to remove this subject?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedSubject.subject_id}>
                  <td>{selectedSubject.subject_code}</td>
                  <td>{selectedSubject.subject_name}</td>
                  <td>{selectedSubject.subject_type}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseDeleteModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmDelete}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const ProgrammesFormModal = ({ handleUpdate, closeModal, object, uni }) => {
  const [programme, setProgramme] = useState('')
  const [name, setName] = useState('');
  const [code, setCode] = useState('');
  const [level, setLevel] = useState('Degree');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchSubject = async () => {
      if (object) {
        setProgramme(object.programme.programme_id)
        setName(object.programme.programme_name);
        setCode(object.programme.programme_code);
        setLevel(object.programme.programme_level);
        setError('');
      }
    };

    fetchSubject();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!name) {

      setError('Please fill in all required fields. At least the university name');
      return;
    }
    // Additional validation logic can be added here

    try {
      let url;
      if (object) {
        url = 'http://localhost:5000/api/admin/editprogramme/';
      } else {
        url = 'http://localhost:5000/api/admin/addprogramme/';
      }
      const response = await axios.post(url, {
        programme_id: programme,
        programme_name: name,
        programme_code: code,
        programme_level: level,
        university_id: uni
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        // Reset form fields
        setName('');
        setCode('');
        setLevel('');
        setError('');

        handleUpdate(response.data);

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  return (
    <div className='create-course-modal'>
      <div className="create-course-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="name">Programme Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="abbreviation">Programme Code:</label>
            <input
              type="text"
              id="abbreviation"
              value={code}
              onChange={(e) => setCode(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="type">Programme Level:</label>
            <select
              id="type"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
              required
            >
              <option value="Doctorate">Doctorate</option>
              <option value="Masters">Masters</option>
              <option value="Degree">Degree</option>
              <option value="Diploma">Diploma</option>
              <option value="PreU">Pre-University</option>
            </select>
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit</button>
            <button className='action-button cancel-button' type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const ProgrammesModal = ({ selectedUni, handleClose }) => {
  const [programmes, setProgrammes] = useState(selectedUni.programmes);
  const [showSubjectModal, setShowSubjectModal] = useState(false);
  const [selectedProgramme, setSelectedProgramme] = useState(null);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);

  const handleEdit = (edu) => {
    setSelectedProgramme(edu);
    setShowEditModal(true);
  };

  const handleDelete = (edu) => {
    setSelectedProgramme(edu);
    setShowDeleteModal(true);
  };

  const handleCreate = () => {
    setShowCreateModal(true)
  };

  const handleCloseCreateModal = () => setShowCreateModal(false);

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleCreateProgramme = (response) => {
    const subjects = [];
    const programme = response;

    const object = { programme, subjects };
    setProgrammes(prevProgrammes => [...prevProgrammes, object]);

    setShowCreateModal(false);
  }

  const handleConfirmEdit = (response) => {
    // Implement logic to mark the report as resolved
    setProgrammes(prevProgrammes => {
      // Find the index of the selected programme in the programmes array
      const index = prevProgrammes.findIndex(programme => programme.programme.programme_id === selectedProgramme.programme.programme_id);
      if (index !== -1) {
        // Create a copy of the programmes array
        const updatedProgrammes = [...prevProgrammes];
        // Create a copy of the nested programme object within the selected programme
        const updatedProgramme = {
          ...updatedProgrammes[index],
          programme: {
            ...updatedProgrammes[index].programme,
            programme_name: response.programme_name,
            programme_code: response.programme_code,
            programme_level: response.programme_level
          }
        };
        // Update the selected programme with the updated nested programme object
        updatedProgrammes[index] = updatedProgramme;
        return updatedProgrammes;
      } else {
        return prevProgrammes; // If programme is not found, return the original array
      }
    });

    setShowEditModal(false);
  };

  const handleConfirmDelete = async () => {
    // Implement logic to mark the report as resolved

    try {
      const response = await axios.post('http://localhost:5000/api/admin/delprogramme/', {
        programme_id: selectedProgramme.programme.programme_id,
        subjects: selectedProgramme.programme.subjects
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setProgrammes(prevProgrammes => {
          // Filter out the selected report from the list of reports
          const updatedProgrammes = prevProgrammes.filter(programme => programme.programme.programme_id !== selectedProgramme.programme.programme_id);
          return updatedProgrammes;
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }

    setShowDeleteModal(false);
  };

  const handleShowSubjects = (edu) => {
    setSelectedProgramme(edu);
    setShowSubjectModal(true);
  };

  const handleCloseSubjects = () => {
    setShowSubjectModal(false);
  };

  return (
    <div className="admin-modal-background">
      <div className="admin-modal">
        <div>
          <a
            className="action-button create-button" onClick={handleCreate}
          >
            Add Programme
          </a>
        </div>
        <div className="admin-modal-buttons">
          <button className="action-button delete-acc-button" onClick={handleClose}>Close</button>
        </div>
        <h3>Programmes for {selectedUni.university.university_name}</h3>
        <table className='modal-table'>
          <thead>
            <tr>
              <th>Code</th>
              <th>Name</th>
              <th>Level</th>
              <th>Subjects</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {programmes.map(programme => (
              <tr key={programme.programme.programme_id}>
                <td>{programme.programme.programme_code}</td>
                <td>{programme.programme.programme_name}</td>
                <td>{programme.programme.programme_level}</td>
                <td><FontAwesomeIcon className='admin-button' icon={faList} title="Show Programmes" onClick={() => handleShowSubjects(programme)} /></td>
                <td>
                  <FontAwesomeIcon className='admin-button' icon={faEdit} title="Edit" onClick={() => handleEdit(programme)} />
                  &nbsp;&nbsp;
                  <FontAwesomeIcon className='admin-button' icon={faTrash} title="Delete" onClick={() => handleDelete(programme)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      {showCreateModal && (
        <ProgrammesFormModal
          handleUpdate={handleCreateProgramme}
          closeModal={handleCloseCreateModal}
          object={null}
          uni={selectedUni.university.university_id}
        />
      )}
      {showEditModal && (
        <ProgrammesFormModal
          handleUpdate={handleConfirmEdit}
          closeModal={handleCloseEditModal}
          object={selectedProgramme}
          uni={selectedUni.university.university_id}
        />
      )}
      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to remove this programme, all it's subjects?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>Code</th>
                  <th>Name</th>
                  <th>Level</th>
                </tr>
              </thead>
              <tbody>
                <tr key={selectedProgramme.programme.programme_id}>
                  <td>{selectedProgramme.programme.programme_code}</td>
                  <td>{selectedProgramme.programme.programme_name}</td>
                  <td>{selectedProgramme.programme.programme_level}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseDeleteModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmDelete}>Confirm</button>
            </div>
          </div>
        </div>
      )}
      {/* Programme Modal */}
      {showSubjectModal && (
        <SubjectsModal selectedProgramme={selectedProgramme} handleClose={handleCloseSubjects} />
      )}
    </div>
  );
}

const UniversityModal = ({ handleUpdate, closeModal, object }) => {
  const [university, setUniversity] = useState('');
  const [name, setName] = useState('');
  const [abbreviation, setAbbreviation] = useState('');
  const [address, setAddress] = useState('');
  const [type, setType] = useState('Public');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchSubject = async () => {
      if (object) {
        setUniversity(object.university.university_id);
        setName(object.university.university_name);
        setAbbreviation(object.university.university_abbr);
        setAddress(object.university.university_address);
        setType(object.university.university_type);
        setError('');
      }
    };

    fetchSubject();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!name) {

      setError('Please fill in all required fields. At least the university name');
      return;
    }
    // Additional validation logic can be added here

    try {
      let url;
      if (object) {
        url = 'http://localhost:5000/api/admin/edituniversity/';
      } else {
        url = 'http://localhost:5000/api/admin/adduniversity/';
      }
      const response = await axios.post(url, {
        university_id: university,
        university_name: name,
        university_abbr: abbreviation,
        university_address: address,
        university_type: type
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        // Reset form fields
        setName('');
        setAbbreviation('');
        setAddress('');
        setType('');
        setError('');

        handleUpdate(response);

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  return (
    <div className='create-course-modal'>
      <div className="create-course-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="name">University Name:</label>
            <input
              type="text"
              id="name"
              value={name}
              onChange={(e) => setName(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="abbreviation">University Abbreviation:</label>
            <input
              type="text"
              id="abbreviation"
              value={abbreviation}
              onChange={(e) => setAbbreviation(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="address">University Address:</label>
            <textarea
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              rows={5}
            />
          </div>
          <div className="form-education">
            <label htmlFor="type">University Type:</label>
            <select
              id="type"
              value={type}
              onChange={(e) => setType(e.target.value)}
              required
            >
              <option value="Public">Public</option>
              <option value="Private">Private</option>
            </select>
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit</button>
            <button className='action-button cancel-button' type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const University = () => {
  const [results, setResults] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showProgrammeModal, setShowProgrammeModal] = useState(false);
  const [selectedUni, setSelectedUni] = useState(null);
  const [updated, setUpdated] = useState(false)

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`http://localhost:5000/api/admin/university/`);

        // Update state with the new data
        setResults(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, [updated]);

  const handleEdit = (uni) => {
    setSelectedUni(uni);
    setShowEditModal(true);
  };

  const handleDelete = (uni) => {
    setSelectedUni(uni);
    console.log(uni);
    setShowDeleteModal(true);
  };

  const handleCreate = () => {
    setShowCreateModal(true)
  };


  const handleCloseCreateModal = () => setShowCreateModal(false);

  const handleCloseEditModal = () => setShowEditModal(false);

  const handleCloseDeleteModal = () => setShowDeleteModal(false);

  const handleCreateUniversity = (response) => {

    setUpdated(!updated);

    setShowCreateModal(false);
  }

  const handleConfirmEdit = (response) => {
    // Implement logic to mark the report as resolved
    setUpdated(!updated);

    setShowEditModal(false);
  };

  const handleConfirmDelete = async () => {
    // Implement logic to mark the report as resolved

    try {
      const response = await axios.post('http://localhost:5000/api/admin/deluniversity/', {
        university: selectedUni
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setResults(prevUniversities => {
          // Filter out the selected report from the list of reports
          const updatedUniversities = prevUniversities.filter(university => university.university.university_id !== selectedUni.university.university_id);
          return updatedUniversities;
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }

    setShowDeleteModal(false);
  };

  const handleShowProgrammes = (uni) => {
    setSelectedUni(uni);
    setShowProgrammeModal(true);
  };

  const handleCloseProgrammes = () => {
    // Implement logic to mark the report as resolved

    setShowProgrammeModal(false);
  };

  return (
    <div id="university-tab" className="tab-content">
      <div>
        <a
          className="action-button create-button" onClick={handleCreate}
        >
          Add University
        </a>
      </div>
      <h2>University Directory</h2>
      <table id="university-table" className="university-table">
        <thead>
          <tr>
            <th>University ID</th>
            <th>Name</th>
            <th>Abbreviation</th>
            <th>Address</th>
            <th>Type</th>
            <th>Programmes</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="university-body">
          {results.map(result => (
            <tr key={result.university.university_id}>
              <td>{result.university.university_id}</td>
              <td>{result.university.university_name}</td>
              <td>{result.university.university_abbr}</td>
              <td>{result.university.university_address}</td>
              <td>{result.university.university_type}</td>
              <td><FontAwesomeIcon className='admin-button' icon={faList} title="Show Programmes" onClick={() => handleShowProgrammes(result)} /></td>
              <td>
                <FontAwesomeIcon className='admin-button' icon={faEdit} title="Edit" onClick={() => handleEdit(result)} />
                &nbsp;&nbsp;
                <FontAwesomeIcon className='admin-button' icon={faTrash} title="Delete" onClick={() => handleDelete(result)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {showCreateModal && (
        <UniversityModal
          handleUpdate={handleCreateUniversity}
          closeModal={handleCloseCreateModal}
          object={null}
        />
      )}
      {showEditModal && (
        <UniversityModal
          handleUpdate={handleConfirmEdit}
          closeModal={handleCloseEditModal}
          object={selectedUni}
        />
      )}
      {/* Delete Modal */}
      {showDeleteModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            <p>Are you sure you want to remove this university, all it's associated programmes and subjects?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>University ID</th>
                  <th>Name</th>
                  <th>Abbreviation</th>
                  <th>Address</th>
                  <th>Type</th>
                </tr>
              </thead>
              <tbody id="university-body">
                <tr key={selectedUni.university.university_id}>
                  <td>{selectedUni.university.university_id}</td>
                  <td>{selectedUni.university.university_name}</td>
                  <td>{selectedUni.university.university_abbr}</td>
                  <td>{selectedUni.university.university_address}</td>
                  <td>{selectedUni.university.university_type}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseDeleteModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmDelete}>Confirm</button>
            </div>
          </div>
        </div>
      )}
      {/* Programme Modal */}
      {showProgrammeModal && (
        <ProgrammesModal selectedUni={selectedUni} handleClose={handleCloseProgrammes} />
      )}
    </div>
  );
}

const UserModal = ({ handleUpdate, closeModal, object }) => {
  const [fname, setFname] = useState('');
  const [lname, setLname] = useState('');
  const [dob, setDob] = useState(null);
  const [phone, setPhone] = useState('');
  const [address, setAddress] = useState('');
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {

    const fetchSubject = async () => {
      if (object) {
        setFname(object.user_fname);
        setLname(object.user_lname);
        setDob(formatDate(object.dob));
        setPhone(object.user_phone);
        setAddress(object.user_address);
        setEmail(object.user_email);
        setError('');
      }
    };

    fetchSubject();
  }, []);

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    // Validate form fields
    if (!fname || !lname) {
      setError('Please fill in all required fields. At least the university name');
      return;
    }
    // Additional validation logic can be added here

    try {

      const response = await axios.post('http://localhost:5000/api/admin/edituser/', {
        user_ID: object.user_ID,
        user_fname: fname,
        user_lname: lname,
        dob: dob,
        user_phone: phone,
        user_address: address,
        user_email: email
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        // Reset form fields
        setFname('');
        setLname('');
        setDob('');
        setPhone('');
        setAddress('');
        setEmail('');
        setError('');
        handleUpdate({
          user_ID: object.user_ID,
          user_fname: fname,
          user_lname: lname,
          dob: dob,
          user_phone: phone,
          user_address: address,
          user_email: email
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  return (
    <div className='create-course-modal'>
      <div className="create-course-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="name">First Name:</label>
            <input
              type="text"
              id="name"
              value={fname}
              onChange={(e) => setFname(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="name">Last Name:</label>
            <input
              type="text"
              id="name"
              value={lname}
              onChange={(e) => setLname(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="dob">Date of Birth:</label>
            <input
              type="date"
              id="dob"
              value={dob ? dob : ''}
              onChange={(e) => setDob(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="phone">Phone:</label>
            <input
              type="tel"
              id="phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="address">Address:</label>
            <input
              type="text"
              id="address"
              value={address}
              onChange={(e) => setAddress(e.target.value)}
              required
            />
          </div>
          <div className="form-education">
            <label htmlFor="email">Email:</label>
            <input
              type="email"
              id="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              required
            />
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit</button>
            <button className='action-button cancel-button' type="button" onClick={closeModal}>Close</button>
          </div>
        </form>
      </div>
    </div>
  );
};

const Users = () => {
  const [users, setUsers] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [showBanModal, setShowBanModal] = useState(false);
  const [showChatModal, setShowChatModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  const navigate = useNavigate();

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`http://localhost:5000/api/admin/user/`);

        // Update state with the new data
        setUsers(response.data);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(users.map(async (result) => {
        if (result && result.user_picture !== '') {
          try {
            const imageUrl = `http://localhost:5000/files/${result.user_picture}`;
            const response = await fetch(imageUrl);
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              console.error('Error fetching image:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          return logo;
        }
      }));
      if (imageUrls.length == 0) {
        setImageUrls(urls);
      }
    };

    fetchImages();
  }, [users]);

  const handleEdit = (user) => {
    setSelectedUser(user);
    setShowEditModal(true);
  };

  const handleBan = (user) => {
    setSelectedUser(user);
    setShowBanModal(true);
  };

  const handleChat = (user) => {
    setSelectedUser(user);
    setShowChatModal(true);
  };

  const handleCloseEditModal = () => setShowEditModal(false);
  const handleCloseBanModal = () => setShowBanModal(false);
  const handleCloseChatModal = () => setShowChatModal(false);

  const handleConfirmEdit = ({ user_ID, user_fname, user_lname, dob, user_phone, user_address, user_email }) => {

    setUsers(prevUsers => {
      // Find the index of the selected user in the users array
      const index = prevUsers.findIndex(user => user.user_ID === user_ID);
      if (index !== -1) {
        // Create a copy of the user object and update the fields with edited data
        const updatedUser = {
          ...prevUsers[index],
          user_fname: user_fname,
          user_lname: user_lname,
          dob: dob,
          user_phone: user_phone,
          user_address: user_address,
          user_email: user_email
        };
        // Create a new array with the updated user object at the corresponding index
        const updatedUsers = [...prevUsers];
        updatedUsers[index] = updatedUser;
        return updatedUsers;
      } else {
        return prevUsers; // If user is not found, return the original array
      }
    });

    // Close the edit modal
    setShowEditModal(false);
  };

  const handleConfirmBan = async () => {
    try {

      const response = await axios.post('http://localhost:5000/api/admin/banuser/', {
        user_ID: selectedUser.user_ID,
      });

      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setUsers(prevUsers => {
          // Find the index of the selected user in the users array
          const index = prevUsers.findIndex(user => user.user_ID === selectedUser.user_ID);
          if (index !== -1) {
            // Create a copy of the user object and update the fields with edited data
            const updatedUser = {
              ...prevUsers[index],
              isBanned: !prevUsers[index].isBanned
            };
            // Create a new array with the updated user object at the corresponding index
            const updatedUsers = [...prevUsers];
            updatedUsers[index] = updatedUser;
            return updatedUsers;
          } else {
            return prevUsers; // If user is not found, return the original array
          }
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }

    setShowBanModal(false);
  };

  const handleConfirmChat = async () => {
    try {
      const chatObj = {
        subject_id: 'admin',
        tutee_id: selectedUser.user_ID,
        tutor_id: 'admin'
      }
      // Make a POST request to the server with the user's email
      const response = await axios.post('http://localhost:5000/api/admin/chat/', {
        class: chatObj
      });

      if (response.data) {
        // Redirect the user to the chat room with the obtained chatId
        setShowChatModal(false);

        navigate(`/message/${response.data.chatId._id}`);
      } else {
        console.error('Chat ID not found in response');
      }

    } catch (error) {
      console.error('Chat error occured:', error);
    }
  };

  return (
    <div id="users-tab" className="tab-content">
      <h2>User Directory</h2>
      <table id="users-table" className="users-table">
        <thead>
          <tr>
            <th>Pic</th>
            <th>ID</th>
            <th>Name</th>
            <th>DOB</th>
            <th>Phone</th>
            <th>Address</th>
            <th>Email</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="users-body">
          {users.length > 0 ? (
            <>
              {users.map(user => (
                <tr key={user.id} className={`${user.isBanned ? 'banned' : ''} ${user.isDeactivated ? 'deactivated' : ''}`}>
                  <td><img className="admin-profile-picture" src={imageUrls[users.indexOf(user)]} alt="Profile" /></td>
                  <td>{user.user_ID}</td>
                  <td>{user.user_fname} {user.user_lname}</td>
                  <td>{formatDate(user.dob)}</td>
                  <td>{user.user_phone}</td>
                  <td>{user.user_address}</td>
                  <td>{user.user_email}</td>
                  <td>
                    <FontAwesomeIcon className='admin-button' icon={faEdit} title="Edit" onClick={() => handleEdit(user)} />
                    <br />
                    <FontAwesomeIcon className='admin-button' icon={faEnvelope} title="Message" onClick={() => handleChat(user)} />
                    <br />
                    {!user.isDeactivated && (<FontAwesomeIcon className='admin-button' icon={faBan} title="Ban" onClick={() => handleBan(user)} />)}
                  </td>
                </tr>
              ))}
            </>
          ) : (
            <div>Users not found or database not connected.</div>
          )}
        </tbody>
      </table>
      {/* Edit User Modal */}
      {showEditModal && (
        <UserModal
          handleUpdate={handleConfirmEdit}
          closeModal={handleCloseEditModal}
          object={selectedUser}
        />
      )}

      {/* Ban User Modal */}
      {showBanModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Confirm Delete</h3>
            {!selectedUser.isBanned ? (
              <p>Are you sure you want to ban this user?</p>
            ) : (
              <p>Are you sure you want to unban this user?</p>
            )}
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>Pic</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody id="users-body">
                <tr key={selectedUser.user_ID}>
                  <td><img className="admin-profile-picture" src={imageUrls[users.indexOf(selectedUser)]} alt="Profile" /></td>
                  <td>{selectedUser.user_ID}</td>
                  <td>{selectedUser.user_fname} {selectedUser.user_lname}</td>
                  <td>{formatDate(selectedUser.dob)}</td>
                  <td>{selectedUser.user_phone}</td>
                  <td>{selectedUser.user_address}</td>
                  <td>{selectedUser.user_email}</td>
                </tr>
              </tbody>
            </table>
            <div className="admin-modal-buttons">
              <button className="action-button delete-acc-button" onClick={handleCloseBanModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmBan}>Confirm</button>
            </div>
          </div>
        </div>
      )}

      {/* Chat Modal */}
      {showChatModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Initiate Chat</h3>
            <p>Would you like to initiate a chat with the selected user?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>Pic</th>
                  <th>ID</th>
                  <th>Name</th>
                  <th>DOB</th>
                  <th>Phone</th>
                  <th>Address</th>
                  <th>Email</th>
                </tr>
              </thead>
              <tbody id="users-body">
                <tr key={selectedUser.user_ID}>
                  <td><img className="admin-profile-picture" src={imageUrls[users.indexOf(selectedUser)]} alt="Profile" /></td>
                  <td>{selectedUser.user_ID}</td>
                  <td>{selectedUser.user_fname} {selectedUser.user_lname}</td>
                  <td>{formatDate(selectedUser.dob)}</td>
                  <td>{selectedUser.user_phone}</td>
                  <td>{selectedUser.user_address}</td>
                  <td>{selectedUser.user_email}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className="action-button delete-acc-button" onClick={handleCloseChatModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmChat}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Analytics = () => {
  const [payments, setPayments] = useState([]);
  const [showRefundModal, setShowRefundModal] = useState(false);
  const [selectedStat, setSelectedStat] = useState(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.post(`http://localhost:5000/api/admin/stat/`);

        // Update state with the new data
        setPayments(response.data);

      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, []);


  const handleRefund = (stat) => {
    setSelectedStat(stat);
    setShowRefundModal(true);
  };

  const handleCloseRefundModal = () => setShowRefundModal(false);

  const handleConfirmRefund = async () => {
    try {
      const response = await axios.post(`http://localhost:5000/api/admin/refstat/`, {
        payment_id: selectedStat.payment.payment_id
      });

      // Update state with the new data
      setShowRefundModal(false);
      if (response.status === 200) {
        setPayments(prevPayments => {
          const updatedPayments = prevPayments.map(payment => {
            if (payment.payment.payment_id === selectedStat.payment.payment_id) {
              // Return a new object with updated payment status
              return {
                ...payment,
                payment: {
                  ...payment.payment,
                  payment_status: 'Refunded'
                }
              };
            }
            return payment;
          });
          return updatedPayments;
        });
      }

    } catch (error) {
      console.error('Error fetching course data:', error);
    }

  };

  return (
    <div id="analytics-tab" className="tab-content">
      <h2>Analytics</h2>
      <table id="analytics-table" className="analytics-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Payer</th>
            <th>Payee</th>
            <th>Course</th>
            <th>Date</th>
            <th>Amount</th>
            <th>Method</th>
            <th>Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody id="analytics-body">
          {payments.map(payment => (
            <tr key={payment.id}>
              <td>{payment.payment.payment_id}</td>
              <td>{payment.payer.user_fname} {payment.payer.user_lname}</td>
              <td>{payment.payee.user_fname} {payment.payee.user_lname}</td>
              <td>{payment.subject.subject_code} {payment.subject.subject_name}</td>
              <td>{formatDate(payment.payment.payment_date)}</td>
              <td>{payment.payment.payment_amount.$numberDecimal}</td>
              <td>{payment.payment.payment_method}</td>
              <td>{payment.payment.payment_status}</td>
              <td>
                <FontAwesomeIcon className='admin-button' icon={faUndo} title="Refund" onClick={() => handleRefund(payment)} />
              </td>
            </tr>
          ))}
        </tbody>
      </table>

      {/* Refund Modal */}
      {showRefundModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Initiate Chat</h3>
            <p>Are you sure you want to refund this payment?</p>
            <table className='modal-table'>
              <thead>
                <tr>
                  <th>ID</th>
                  <th>Payer</th>
                  <th>Payee</th>
                  <th>Course</th>
                  <th>Date</th>
                  <th>Amount</th>
                  <th>Method</th>
                  <th>Status</th>
                </tr>
              </thead>
              <tbody id="analytics-body">
                <tr key={selectedStat.payment.payment_id}>
                  <td>{selectedStat.payment.payment_id}</td>
                  <td>{selectedStat.payer.user_fname} {selectedStat.payer.user_lname}</td>
                  <td>{selectedStat.payee.user_fname} {selectedStat.payee.user_lname}</td>
                  <td>{selectedStat.subject.subject_code} {selectedStat.subject.subject_name}</td>
                  <td>{formatDate(selectedStat.payment.payment_date)}</td>
                  <td>{selectedStat.payment.payment_amount.$numberDecimal}</td>
                  <td>{selectedStat.payment.payment_method}</td>
                  <td>{selectedStat.payment.payment_status}</td>
                </tr>
              </tbody>
            </table>
            <div>
              <button className="action-button delete-acc-button" onClick={handleCloseRefundModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={handleConfirmRefund}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const DeletedCourse = () => {
  const [searchResults, setSearchResults] = useState([]);
  const [imageUrls, setImageUrls] = useState([]);
  const [selectedCourse, setSelectedCourse] = useState(null);

  const location = useLocation();
  const [placeholderString, setPlaceholderString] = useState('Searching results..');
  const [showModal, setShowModal] = useState(false);

  const user = useSelector((state) => state.user);

  const calcAvg = (array, prop) => {
    if (array.length == 0) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
      total += array[i][prop];
    }
    let avg = total / array.length;
    return avg;
  }

  useEffect(() => {
    const fetchCourses = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/admin/course');
          // Assuming the server responds with the user data
          const courses = response.data;

          setSearchResults(courses);
          setPlaceholderString("No results returned.")
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchCourses();
  }, [user]);  // Run the effect whenever the user changes

  useEffect(() => {
    const fetchImages = async () => {
      const urls = await Promise.all(searchResults.map(async (result) => {
        if (result.user && result.user.user_picture !== '') {
          try {
            const imageUrl = `http://localhost:5000/files/${result.user.user_picture}`;
            const response = await fetch(imageUrl);
            if (response.ok) {
              const blob = await response.blob();
              const objectUrl = URL.createObjectURL(blob);
              return objectUrl;
            } else {
              console.error('Error fetching image:', response.statusText);
            }
          } catch (error) {
            console.error('Error fetching image:', error);
          }
        } else {
          return logo;
        }
      }));
      setImageUrls(urls);
    };

    fetchImages();
  }, [searchResults]);

  // Function to handle opening the modal
  const openModal = (result) => {
    setSelectedCourse(result);
    setShowModal(true);
  };

  // Function to handle closing the modal
  const closeModal = () => {
    setShowModal(false);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleRestoreCourse = async (course) => {
    // Logic to handle course creation
    // You can submit the courseDetails to your backend API here
    // After successfully creating the course, you can close the modal
    try {
      const response = await axios.post('http://localhost:5000/api/delCourse/', course.course);
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        // Check if the update was successful
        refreshPage();
      } else {
        // Display an error message based on the response status
        console.error('Deactivation unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const displayOnlineClassMode = (boolean) => {
    if (boolean) {
      return "Online";
    };
  };

  const displayOfflineClassMode = (boolean) => {
    if (boolean) {
      return "Physical";
    };
  };

  return (
    <div id="analytics-tab" className="tab-content">
      <div className="listing-page">
        <div className='sorting-and-info-listing-container'>
          <p className="listing-info">Showing {searchResults.length} of {searchResults.length} results for: </p>
        </div>
        <div className="listing-results">
          {/* Dummy content */}
          {searchResults.length === 0 ? (
            <div className="no-results-message">
              <p>{placeholderString}</p>
            </div>
          ) : (searchResults.map(result => (
            <div className={`listing-item ${result.course.isDeactivated ? 'listing-deactivated' : ''}`} key={result.subject.subject_id}>
              <div className="profile-info">
                <div>
                  <React.Suspense>
                    <img className="profile-picture" src={imageUrls[searchResults.indexOf(result)]} alt="Profile" />
                  </React.Suspense>
                  <StarRating rating={calcAvg(result.ratings, "rating")} />
                </div>
              </div>
              <div className="listing-course-info">
                <p><span>Subject Name:</span> {result.subject.subject_code} - {result.subject.subject_name}</p>
                <p><span>Tutor Name:</span> {result.user.user_fname} {result.user.user_lname}</p>
                <p><span>CGPA:</span>{result.userEdu.grades}</p>
                <p><span>Grade Obtained:</span> {result.course.grade}</p>
                <p><span>Free Trial:</span> {result.course.free_trial}</p>
                <p><span>Class Mode:</span>{displayOnlineClassMode(result.course.online_enable)} &nbsp; {displayOfflineClassMode(result.course.offline_enable)}</p>
                <p><span>RM/Hr:</span> {result.course.price.$numberDecimal}</p>
              </div>
              <div className="actions">
                <a
                  onClick={() => openModal(result)}
                  className="action-button delete-acc-button"
                >
                  Restore <br /> Lesson
                </a>
              </div>
            </div>
          )))}
        </div>
      </div>
      {showModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Initiate Chat</h3>
            <p>Are you sure you want to restore this course?</p>
            <div className={`admin-listing listing-item ${selectedCourse.course.isDeactivated ? 'listing-deactivated' : ''}`} key={selectedCourse.subject.subject_id}>
              <div className="profile-info">
                <div>
                  <React.Suspense>
                    <img className="profile-picture" src={imageUrls[searchResults.indexOf(selectedCourse)]} alt="Profile" />
                  </React.Suspense>
                  <StarRating rating={calcAvg(selectedCourse.ratings, "rating")} />
                </div>
              </div>
              <div className="listing-course-info">
                <p><span>Subject Name:</span> {selectedCourse.subject.subject_code} - {selectedCourse.subject.subject_name}</p>
                <p><span>Tutor Name:</span> {selectedCourse.user.user_fname} {selectedCourse.user.user_lname}</p>
                <p><span>CGPA:</span>{selectedCourse.userEdu.grades}</p>
                <p><span>Grade Obtained:</span> {selectedCourse.course.grade}</p>
                <p><span>Free Trial:</span> {selectedCourse.course.free_trial}</p>
                <p><span>Class Mode:</span>{displayOnlineClassMode(selectedCourse.course.online_enable)} &nbsp; {displayOfflineClassMode(selectedCourse.course.offline_enable)}</p>
                <p><span>RM/Hr:</span> {selectedCourse.course.price.$numberDecimal}</p>
              </div>
            </div>
            <div>
              <button className="action-button delete-acc-button" onClick={() => closeModal}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={() => handleRestoreCourse(selectedCourse)}>Confirm</button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

const Admin = () => {
  const { tab } = useParams();
  const [activeTab, setActiveTab] = useState(tab || 'reports');
  const user = useSelector((state) => state.user);

  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    // Check if the user is already logged in
    // Assuming isLoggedIn and setIsLoggedIn are passed as props
    if (user !== 'admin') {
      navigate('/');
    }
  }, [isLoggedIn]);

  useEffect(() => {
    setActiveTab(tab || 'reports');
  }, [tab]);

  // Other logic for different tabs
  return (
    <div className="profile-page">
      <div className='profile-settings'>
        <div className="tab-container">
          {/* Tabs Selection */}
          <a onClick={() => setActiveTab('reports')} className={activeTab === 'reports' ? 'active' : ''}>User Reports</a><br />
          <a onClick={() => setActiveTab('requests')} className={activeTab === 'requests' ? 'active' : ''}>Pending Approvals</a><br />
          <a onClick={() => setActiveTab('university')} className={activeTab === 'university' ? 'active' : ''}>University Directory</a><br />
          <a onClick={() => setActiveTab('users')} className={activeTab === 'users' ? 'active' : ''}>User Directory</a><br />
          <a onClick={() => setActiveTab('analytics')} className={activeTab === 'analytics' ? 'active' : ''}>Platform Analytics</a><br />
          <a onClick={() => setActiveTab('courses')} className={activeTab === 'courses' ? 'active' : ''}>Deleted Courses</a>
        </div>
        <div className="tab-window">
          {/* Tabs Display */}
          {activeTab === 'reports' && <Reports />}
          {activeTab === 'requests' && <Requests />}
          {activeTab === 'university' && <University />}
          {activeTab === 'users' && <Users />}
          {activeTab === 'analytics' && <Analytics />}
          {activeTab === 'courses' && <DeletedCourse />}
          {/* ... (other tabs) */}
        </div>
      </div>
    </div>
  );
};

export default Admin;