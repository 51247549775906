// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.action-button {
    font-family: 'Ubuntu', sans-serif; /* Replace 'YourFont' with the actual font you are using */
    font-size: 2em;
    background-color: #4caf50; /* Green color, adjust as needed */
    color: white;
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.2s;
  }
  
  .action-button:hover {
    background-color: #45a049; /* Darker green on hover, adjust as needed */
  }
  
  .action-button:active {
    transform: scale(0.98); /* Slight scale down on click for a pressed effect */
  }`, "",{"version":3,"sources":["webpack://./src/pages/stylesheet/button.css"],"names":[],"mappings":"AAAA;IACI,iCAAiC,EAAE,0DAA0D;IAC7F,cAAc;IACd,yBAAyB,EAAE,kCAAkC;IAC7D,YAAY;IACZ,kBAAkB;IAClB,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iDAAiD;EACnD;;EAEA;IACE,yBAAyB,EAAE,4CAA4C;EACzE;;EAEA;IACE,sBAAsB,EAAE,oDAAoD;EAC9E","sourcesContent":[".action-button {\n    font-family: 'Ubuntu', sans-serif; /* Replace 'YourFont' with the actual font you are using */\n    font-size: 2em;\n    background-color: #4caf50; /* Green color, adjust as needed */\n    color: white;\n    padding: 12px 24px;\n    border: none;\n    border-radius: 8px;\n    cursor: pointer;\n    transition: background-color 0.3s, transform 0.2s;\n  }\n  \n  .action-button:hover {\n    background-color: #45a049; /* Darker green on hover, adjust as needed */\n  }\n  \n  .action-button:active {\n    transform: scale(0.98); /* Slight scale down on click for a pressed effect */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
