// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.star-rating {
    display: flex;
  }
  
  .star {
    color: gold; /* Default star color */
    font-size: 24px; /* Adjust the size based on your design */
  }
  
  .starBox {
    display: flex;
    align-items: center;
  }
  
  .starBox .ratingText {
    margin-right: 3px; /* Add some space between the rating text and stars */
  }`, "",{"version":3,"sources":["webpack://./src/pages/stylesheet/starRatings.css"],"names":[],"mappings":"AAAA;IACI,aAAa;EACf;;EAEA;IACE,WAAW,EAAE,uBAAuB;IACpC,eAAe,EAAE,yCAAyC;EAC5D;;EAEA;IACE,aAAa;IACb,mBAAmB;EACrB;;EAEA;IACE,iBAAiB,EAAE,qDAAqD;EAC1E","sourcesContent":[".star-rating {\n    display: flex;\n  }\n  \n  .star {\n    color: gold; /* Default star color */\n    font-size: 24px; /* Adjust the size based on your design */\n  }\n  \n  .starBox {\n    display: flex;\n    align-items: center;\n  }\n  \n  .starBox .ratingText {\n    margin-right: 3px; /* Add some space between the rating text and stars */\n  }"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
