// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.report-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }
  
  .report-heading {
    margin-bottom: 20px;
    font-size: 24px;
  }
  
  .report-form {
    width: 400px;
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 8px;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
  }

  .report-contents {
    min-height: 100px;
  }
  
  .report-contents, .supporting-url {
    width: 90%;
    padding: 8px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  .submit-btn {
    width: 100%;
    font-size: 16px;
    padding: 10px;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  `, "",{"version":3,"sources":["webpack://./src/pages/stylesheet/user/create.css"],"names":[],"mappings":"AAAA;IACI,aAAa;IACb,sBAAsB;IACtB,uBAAuB;IACvB,mBAAmB;IACnB,WAAW;EACb;;EAEA;IACE,mBAAmB;IACnB,eAAe;EACjB;;EAEA;IACE,YAAY;IACZ,aAAa;IACb,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,mBAAmB;EACrB;;EAEA;IACE,cAAc;IACd,kBAAkB;EACpB;;EAEA;IACE,iBAAiB;EACnB;;EAEA;IACE,UAAU;IACV,YAAY;IACZ,sBAAsB;IACtB,kBAAkB;EACpB;;EAEA;IACE,WAAW;IACX,eAAe;IACf,aAAa;IACb,WAAW;IACX,YAAY;IACZ,kBAAkB;IAClB,eAAe;IACf,iCAAiC;EACnC","sourcesContent":[".report-container {\n    display: flex;\n    flex-direction: column;\n    justify-content: center;\n    align-items: center;\n    width: 100%;\n  }\n  \n  .report-heading {\n    margin-bottom: 20px;\n    font-size: 24px;\n  }\n  \n  .report-form {\n    width: 400px;\n    padding: 20px;\n    border: 1px solid #ccc;\n    border-radius: 8px;\n  }\n  \n  .form-group {\n    margin-bottom: 20px;\n  }\n  \n  .form-group label {\n    display: block;\n    margin-bottom: 5px;\n  }\n\n  .report-contents {\n    min-height: 100px;\n  }\n  \n  .report-contents, .supporting-url {\n    width: 90%;\n    padding: 8px;\n    border: 1px solid #ccc;\n    border-radius: 4px;\n  }\n  \n  .submit-btn {\n    width: 100%;\n    font-size: 16px;\n    padding: 10px;\n    color: #fff;\n    border: none;\n    border-radius: 4px;\n    cursor: pointer;\n    transition: background-color 0.3s;\n  }\n  "],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
