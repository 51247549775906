import './../stylesheet/MainPage.css';
import './../stylesheet/button.css';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import logo from './../../images/logo.png';
import mainPageImg from './../../images/mainPagePic.jpeg';
import mainPageImg2 from './../../images/mainPagePic2.webp';
import mainPageImg3 from './../../images/mainPagePic3.jpeg';
import mainPageImg4 from './../../images/mainPagePic4.webp';
import STEMImage from './../../images/STEM.png';
import BusinessImage from './../../images/Business and Finance.png';
import HealthImage from './../../images/Health and Medicine.png';
import ArtsImage from './../../images/Arts and Humanities.png';
import founder from './../../images/founder.jpg';
import student from './../../images/student.png';
import signUp from './../../images/signUp.jpg';

const Slideshow = () => {
  const images = [mainPageImg, mainPageImg2, mainPageImg3, mainPageImg4];
  const [index, setIndex] = useState(0);

  useEffect(() => {
    const interval = setInterval(() => {
      setIndex((prevIndex) => (prevIndex + 1) % images.length);
    }, 5000); // Change the interval time (in milliseconds) as needed

    return () => clearInterval(interval);
  }, [index]);

  const nextSlide = () => {
    setIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevSlide = () => {
    setIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  return (
    <div className="slideshow-container">
      {images.map((img, i) => (
        <div key={i} className="slide-container">
          <img alt={`Slide ${i + 1}`}
            src={img}
            className={`mainPic ${i === index ? 'current' : i === (index + 1) % images.length ? 'next' : i === (index - 1 + images.length) % images.length ? 'prev' : ''}`}
            style={{ left: `${100 * (i - index)}%` }}
          />
          <div className="caption" style={{ right: `${100 * (i - index)}%` }}>
            {i == 0 && (
              <div>
                <h2 className="caption-header">Catered to your needs..</h2>
                <p className="caption-paragraph">Courses specifically tailored to meet the academic syllabus of your university.
                  <br />Get started with us.</p>
              </div>
            )}
            {i == 1 && (
              <div>
                <h2 className="caption-header">Affordable Rates</h2>
                <p className="caption-paragraph">The tutors on our platform are students or alumnis who were also students.
                <br />You can expect more affordable rates compared to external tutors.</p>
              </div>
            )}
            {i == 2 && (
              <div>
                <h2 className="caption-header">Flexible Learning</h2>
                <p className="caption-paragraph">Prefer online learning over physical learning? Or you like to meet your tutor or tutee in person?
                <br />We got you covered.</p>
              </div>
            )}
            {i == 3 && (
              <div>
                <h2 className="caption-header">Be Your Own Boss..!</h2>
                <p className="caption-paragraph">Sign up as our tutor, and you're free to control your own rates, hours, and time.
                <br />There is no better way to earn a side part-time income as a student!</p>
              </div>
            )}
          </div>
        </div>
      ))}
      <button onClick={prevSlide} className="prev-button">
        &#10094;
      </button>
      <button onClick={nextSlide} className="next-button">
        &#10095;
      </button>
    </div>
  );


};

const Logo = () => {
  return (
    <div className="logo-middle">
      <img src={logo} alt="Company Logo" className="course-logo" />
    </div>
  );
};

const CoursesSection = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [animationsTriggered, setAnimationsTriggered] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const currentPosition = window.scrollY;
      console.log(currentPosition)
      setScrollPosition(currentPosition);

      if (currentPosition > 750 && !animationsTriggered) {
        const scrollAnimation = () => {
          setTimeout(() => {
            window.scrollTo({ top: 740, behavior: 'instant' });
            window.scrollTo({ top: 780, behavior: 'smooth' });
          }, 100);
        };

        requestAnimationFrame(() => {
          scrollAnimation();
          document.body.classList.add('no-scroll');
          setAnimationsTriggered(true);

          // Trigger animations here
          const courseItems = document.querySelectorAll('.course-item');
          courseItems.forEach((item, index) => {
            setTimeout(() => {
              item.classList.add('visible');
            }, index * 400); // Adjust the delay as needed
          });

          // For logo
          const logoElements = document.querySelectorAll('.logo-middle');
          logoElements.forEach((logoElement, index) => {
            setTimeout(() => {
              logoElement.classList.add('visible');
            }, index * 800); // Adjust the delay as needed
          });

          // Unlock scroll after animations are complete
          setTimeout(() => {
            document.body.classList.remove('no-scroll');
          }, courseItems.length * 400); // Adjust the delay based on the total animation time
        });
      } else if (currentPosition <= 700 && animationsTriggered) {
        // Handle reversing animations when scrolling up
        document.body.classList.remove('no-scroll');
      }
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, [animationsTriggered]);

  // Define the images and captions
  const courses = [
    { src: STEMImage, caption: 'STEM Programs' },
    { src: BusinessImage, caption: 'Business and Finance' },
    { src: HealthImage, caption: 'Health and Medicine' },
    { src: ArtsImage, caption: 'Arts and Humanities' },
  ];

  return (
    <div className="courses-container">
      <Logo />
      {courses.map((course, index) => (
        <div key={index} className={`course-item`}>
          <img src={course.src} alt={course.caption} className="course-image" />
        </div>
      ))}
    </div>
  );
};

const FounderSection = () => {
  return (
    <section className="founder-section">
      <h2 className="section-heading">Meet the Founders and The Team</h2>
      <p className="section-paragraph">
        Meet Brandon, the visionary behind our platform.
        With a passion for making educational and financial support more attainable and widely available to university students,
        Brandon founded IntraTutor with the goal of connecting university students together in a tutor-tutee relationship.
      </p>
      <p className="section-paragraph">
        As a Software Engineering graduate from Tunku Abdul Rahman University of Management and Technology,
        Brandon brings a wealth of knowledge in computer science,
        and a team of highly motivated cofounders has been instrumental in supporting the IntraTutor's system.
      </p>

      <div className="cofounders">
        <div className="cofounder-item">
          <img src={founder} alt="Cofounder" className="cofounder-image" />
          <p className="cofounder-info">
            <strong>Brandon</strong><br />
            Visionary & Founder<br />
            Software Engineering Graduate
          </p>
        </div>
        <div className="cofounder-item">
          <img src={founder} alt="Cofounder" className="cofounder-image" />
          <p className="cofounder-info">
          <strong>Brandon</strong><br />
            Visionary & Founder<br />
            Software Engineering Graduate
          </p>
        </div>
        <div className="cofounder-item">
          <img src={founder} alt="Cofounder" className="cofounder-image" />
          <p className="cofounder-info">
          <strong>Brandon</strong><br />
            Visionary & Founder<br />
            Software Engineering Graduate
          </p>
        </div>
      </div>
    </section>
  );
};

const SignupBenefits = () => {
  const [scrollPosition, setScrollPosition] = useState(0);
  const [imageVisible, setImageVisible] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  useEffect(() => {
    // Adjust the threshold value as needed
    const threshold = 2300;
    if (scrollPosition > threshold && !imageVisible) {
      setImageVisible(true);
    }
  }, [scrollPosition, imageVisible]);

  return (
    <section className="signup-benefits">
      <h2 className="section-heading">Is IntraTutor for me..?</h2>
      <div className="content-container">
        <p className="intro-paragraph">Are you a college or university student facing academic or financial challenges that seem insurmountable?
          <br />Do traditional tutoring services fall short of your unique needs?
          <br />Are you a student eager to share your knowledge and earn a side income?</p>
        <div className={`image-container ${imageVisible ? 'visible' : ''}`}>
          <img src={student} alt="university student" className="student-image" />
        </div>
        <p className="closing-statement">
          If you resonate with any of these situations,
          <span className='highlight'> then IntraTutor is the solution you've been searching for! </ span>
          <br />Our platform is tailored to meet the diverse needs of students like you.
          <br />Whether you seek academic assistance or want to become a tutor, IntraTutor is your one-stop destination.
        </p>
      </div>
    </section>
  );
};

const MainPage = () => {
  const user = useSelector((state) => state.user);
    return (
      <div>

        {/* THE MAIN CONTAINER */}

        <Slideshow />

        {/* ABOUT THE PLATFORM */}

        <section className="section-container" id='section-one'>
          <h2 className='section-heading'>Made By Students, For Students</h2>
          <p className='section-paragraph'>
            Welcome to IntraTutor, an online tutoring platform dedicated to providing tailored courses for your academic success.
          </p>
        </section>

        {/* END OF ABOUT THE PLATFORM */}

        {/* ABOUT THE COURSES */}

        <CoursesSection />

        <section className="section-container" id='section-two'>
          <h2 id='courses-heading' className='section-heading'>We got all that you need, and wants all that you have to offer..</h2>
          <p className='section-paragraph'>
            From the pure or applied sciences all the way to arts and humanities or even creative arts, if your university offers it, we have it, or we want it!
          </p>
        </section>

        {/* END OF ABOUT THE COURSES */}

        {/* ABOUT THE FOUNDER */}

        <FounderSection />

        {/* END OF ABOUT THE FOUNDER */}

        {/* BENEFITS OF SIGNING UP */}

        <SignupBenefits />

        {/* END OF BENEFITS OF SIGNING UP */}

        {/* REGISTRATION LINK */}

        <section className="registration-link">
          <div className="background-container">
            <img src={signUp} alt="university students sitting" className="registration-image" />
          </div>
          <div className="registration-container">
            <div className="text-container">
            <h2 className="section-heading">What else are you waiting for..?</h2>
              <p className="call-to-action section-paragraph">
                Join us today and experience the benefits that thousands of students are already enjoying.
                Elevate your academic journey, connect with peers, and unlock new opportunities.
                If you're serious about your education and growth, then this place is exactly for you!
              </p>
              <ul className='registration-list'>
                <li>Free sign up, become a tutor for free!</li>
                <li>No lesson quotas, teach at your own pace!</li>
                <li>Reasonable revenue sharing rates!</li>
                <li>Teach subjects you already learned before!</li>
                <li>Retain the ability to learn as a tutee!</li>
                <li>No strings attached, free to leave anytime!</li>
              </ul>
              {user && (
                <a href='/user/course-listings'><button className="action-button" >Teach Now!</button></a>
              )}
              {!user && (
                <a href='/register'><button className="action-button" >Teach Now!</button></a>
              )}
            </div>
          </div>
        </section>

        {/* END OF REGISTRATION LINK */}

        {/* END OF MAIN CONTAINER */}
      </div>
    );
  }


export default MainPage;
