import './../../stylesheet/user/account.css'
import './../../stylesheet/button.css'
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import logo from './../../../images/logo.png';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEdit, faTrash, faCamera, faTimes, faCheck } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useSelector } from 'react-redux';
import { loginSuccess, logout } from './../../../redux/actions';
import PasswordModal from './passwordModal';

const passwordRegex = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)[a-zA-Z\d]{8,}$/;

const formatDate = (dateString) => {
  const date = new Date(dateString);
  const year = date.getFullYear();
  const month = String(date.getMonth() + 1).padStart(2, '0');
  const day = String(date.getDate()).padStart(2, '0');
  return `${year}-${month}-${day}`;
};

const NewPasswordModal = ({ handleUpdate, closeModal }) => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message

  const handleUpdateWithVerification = () => {

    // Pass the entered password for verification
    if (!password || !confirmPassword) {
      setError('Please fill in all required fields.');
      return;
    }

    // Check if passwords match
    if (password !== confirmPassword) {
      setError('Passwords do not match');
      return;
    }

    if (!passwordRegex.test(password)) {
      setError('Invalid password format. Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number.');
      return;
    }
    handleUpdate(password);
  };
  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>Please enter your new password:</p>
        {error && <div className="error-message">{error}</div>}
        <input
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Enter your password"
          title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number"
          required
        />
        <p>Please enter your confirm new password:</p>
        <input
          type="password"
          value={confirmPassword}
          onChange={(e) => setConfirmPassword(e.target.value)}
          pattern={passwordRegex}
          placeholder="Enter your confirm password"
          title="Minimum eight characters, at least one uppercase letter, one lowercase letter, and one number"
          required
        />
        <button onClick={handleUpdateWithVerification}>Update</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

const VerificationModal = ({ userID, closeModal }) => {
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message
  const handleVerify = async () => {
    try {
      const response = await axios.post('http://localhost:5000/api/updateemail', {
        userID,
        verificationCode
      });

      // If verification is successful, proceed with updating
      if (response.status === 200) {
        // Call the function to update the user information
        // ...

        // Close the modal
        closeModal();
      }
    } catch (error) {
      setError('Error verifying code. Please try again.');
    }
  };

  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>An email with a verification code has been sent to your new email.</p>
        {error && <div className="error-message">{error}</div>}
        <input
          type="text"
          placeholder="Enter verification code"
          value={verificationCode}
          onChange={(e) => setVerificationCode(e.target.value)}
        />
        <button onClick={handleVerify}>Submit</button>
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

const EmailVerificationModal = ({ closeModal, onSuccess, userEmail }) => {
  const [email, setEmail] = useState(userEmail);
  const [verificationCode, setVerificationCode] = useState('');
  const [error, setError] = useState('');
  const [codeSent, setCodeSent] = useState(false);
  const [dispayCodeSent, setDisplayCodeSent] = useState(false);
  const [countdown, setCountdown] = useState(0);

  useEffect(() => {
    let timer = null;
    if (codeSent || countdown > 0) {
      timer = setTimeout(() => {
        setCountdown(countdown - 1);
        setCodeSent(false);
      }, 1000);
    } else if (countdown === 0) {
      setCodeSent(false);
      clearTimeout(timer);
    }

    return () => clearTimeout(timer);
  }, [codeSent, countdown]);

  const handleVerify = async () => {
    try {
      console.log("This is reached too");
      const userData = await axios.post('http://localhost:5000/api/compareemail', {
        user_email: email
      });
      console.log(userData);
      console.log(email);
      console.log(verificationCode);
      const response = await axios.post('http://localhost:5000/api/updateemail', {
        userID: userData.data.user_ID,
        verificationCode: verificationCode,
      });
      if (response.status === 200) {
        // Handle successful verification
        console.log(response.status)
        onSuccess(email);
      } else {
        setError('Error verifying code. Please try again.');
      }
    } catch (error) {
      setError('Error verifying code. Please try again.');
    }
  };

  const handleSendCode = async () => {
    // Simulate sending the verification code
    setDisplayCodeSent(true);
    setCodeSent(true);
    setCountdown(45);

    try {
      const userData = await axios.post('http://localhost:5000/api/compareemail', {
        user_email: email
      });
      console.log(userData)
      const response = await axios.post('http://localhost:5000/api/verifyemail', {
        user_ID: userData.data.user_ID,
        user_email: email
      });
      if (response.status === 201) {
        setError('');
        setCodeSent(true);
        setCountdown(45);
      } else {
        setError('Failed to send verification code. Please try again.');
      }
    } catch (error) {
      setError('Failed to send verification code. Please try again.');
    }
  };

  return (
    <div className="verification-container">
      <div className="password-verification verification-content">
        <p>Press the button to send a verification code to the email associated with your account:</p>
        <p className='email-paragraph'>{email}</p>

        <button disabled={codeSent || countdown > 0} onClick={handleSendCode}>
          {codeSent ? 'Code Sent' : countdown > 0 ? `Resend Code (${countdown}s)` : 'Send Verification Code'}
        </button>

        {dispayCodeSent && (
          <p>An email with a verification code has been sent to your email.</p>
        )}

        {dispayCodeSent && (
          <input
            type="text"
            placeholder="Enter verification code"
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
            required
          />
        )}

        {error && <div className="error-message">{error}</div>}
        {dispayCodeSent && (
          <button onClick={handleVerify}>Submit</button>
        )}
        <button onClick={closeModal}>Cancel</button>
      </div>
    </div>
  );
};

const EducationModal = ({ handleClose, handleSave, object }) => {
  const [universities, setUniversities] = useState([]);
  const [programs, setPrograms] = useState([]);
  const [university, setUniversity] = useState('');
  const [level, setLevel] = useState('');
  const [major, setMajor] = useState('');
  const [grades, setGrades] = useState('');
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [status, setStatus] = useState('Pending');
  const [fileURL, setFileURL] = useState('');
  const [dataLoaded, setDataLoaded] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {


    const fetchUniversities = async () => {
      try {
        const response = await axios.get('http://localhost:5000/api/getuniversity');
        setUniversities(response.data);
      } catch (error) {
        console.error('Error fetching universities:', error);
      }
    };
    const fetchData = async () => {
      await fetchUniversities().then(() => setDataLoaded(true)); // Set dataLoaded to true after universities are fetched
      if (object) {
        setUniversity(object.university.university_id);
        setLevel(object.programme.programme_level);
        setMajor(object.programme.programme_id);
        setGrades(object.userEdu.grades);
        setStartDate(formatDate(object.userEdu.start_year));
        setEndDate(formatDate(object.userEdu.end_year));
        setFileURL(object.userEdu.files_url);
      }
    };

    //fetchUniversities().then(() => setDataLoaded(true)); // Set dataLoaded to true after universities are fetched
    fetchData();
  }, [object]);

  useEffect(() => {
    const fetchPrograms = async () => {
      if (level && university) {
        try {
          const response = await axios.get(`http://localhost:5000/api/getmajor/${level}/${university}`);
          setPrograms(response.data);
        } catch (error) {
          console.error(`Error fetching programs for level ${level}:`, error);
        }
      }
    };

    fetchPrograms();

  }, [level, university]);

  useEffect(() => {
    const remainData = async () => {

      if (object) {
        setError("Cannot edit University and Major. Delete history if needed.");
        setUniversity(object.university.university_id);
        setLevel(object.programme.programme_level);
        setMajor(object.programme.programme_id);
      }
    };

    const fetchPrograms = async () => {
      if (level && university) {
        try {
          const response = await axios.get(`http://localhost:5000/api/getmajor/${level}/${object.university.university_id}`);
          setPrograms(response.data);
        } catch (error) {
          console.error(`Error fetching programs for level ${level}:`, error);
        }
      }
    };

    fetchPrograms();

    //fetchUniversities().then(() => setDataLoaded(true)); // Set dataLoaded to true after universities are fetched
    remainData();

  }, [level, university, major]);

  const handleReset = () => {
    setUniversity('');
    setLevel('');
    setMajor('');
    setGrades('');
    setStartDate('');
    setEndDate('');
    setFileURL('');
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    if (!university || !level || !major || !grades || !startDate || !endDate || !status || !fileURL) {
      setError("Please enter all required fields.");
      return;
    }

    const now = new Date();

    if (endDate < startDate) {
      setError("End date cannot be earlier than start date.");
      return;
    }

    if (startDate > formatDate(now)) {
      setError("Start date cannot be later than now.");
      return;
    }

    // Call the handleSave function passed from the parent component
    handleSave({ university, level, major, grades, startDate, endDate, status, fileURL });
    handleClose();
    handleReset();
  };

  if (!dataLoaded) {
    // Return a loading indicator while data is being fetched
    return <div>Loading...</div>;
  }

  return (
    <div className='education-container'>
      <div className="education-content">
        {error && <div className="error-message">{error}</div>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-education">
            <label htmlFor="university">University:</label>
            <select
              id="university-form"
              value={university}
              onChange={(e) => setUniversity(e.target.value)}
            >
              <option value="">Select University</option>
              {/* Add options for universities here */}
              {universities.map((university) => (
                <option value={university.university_id}>{university.university_name}</option>
              ))}
            </select>
          </div>
          <div className="form-education">
            <label htmlFor="level">Level:</label>
            <select
              id="level-form"
              value={level}
              onChange={(e) => setLevel(e.target.value)}
            >
              <option value="">Select Level</option>
              <option value="PreU">Pre-University</option>
              <option value="Diploma">Diploma</option>
              <option value="Degree">Degree</option>
              <option value="Postgrad">Post-Graduate</option>
            </select>
          </div>
          <div className="form-education">
            <label htmlFor="major">Major:</label>
            <select
              id="major-form"
              value={major}
              onChange={(e) => setMajor(e.target.value)}
            >
              <option value="">Select Major</option>
              {/* Add options for majors here */}
              {programs.map((program) => (
                <option value={program.programme_id}>{program.programme_name}</option>
              ))}
            </select>
          </div>
          <div className="form-education">
            <label htmlFor="startDate">Start Date:</label>
            <input
              type="date"
              id="startDate-form"
              value={startDate}
              onChange={(e) => setStartDate(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="endDate">End Date:</label>
            <input
              type="date"
              id="endDate-form"
              value={endDate}
              onChange={(e) => setEndDate(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="grades">Grades:</label>
            <input
              type="text"
              id="grades-form"
              value={grades}
              onChange={(e) => setGrades(e.target.value)}
            />
          </div>
          <div className="form-education">
            <label htmlFor="fileURL">Upload certificates to GDrive & paste URL:</label>
            <input
              type="text"
              id="grades-form"
              value={fileURL}
              onChange={(e) => setFileURL(e.target.value)}
            />
          </div>
          <div className='button-groups'>
            <button className='action-button' type="submit">Submit Education History</button>
            <button className='action-button cancel-button' type="button" onClick={handleClose}>Close</button>
          </div>
        </form>
      </div>
    </div>

  );
};

const Account = () => {
  const [selectedFile, setSelectedFile] = useState(null);
  const [imgURL, setImgURL] = useState(logo)
  const [userData, setUserData] = useState({});
  const [educationData, setEducationData] = useState([]);
  const [selectedEducation, setSelectedEducation] = useState([]);
  const [showPasswordModal, setShowPasswordModal] = useState(false);
  const [showNewPasswordModal, setShowNewPasswordModal] = useState(false);
  const [showResetPasswordModal, setShowResetPasswordModal] = useState(false);
  const [showEmailModal, setShowEmailModal] = useState(false);
  const [showEmailVerificationModal, setShowEmailVerificationModal] = useState(false);
  const [showConfirmDelAccModal, setShowConfirmDelAccModal] = useState(false);
  const [showDelAccModal, setShowDelAccModal] = useState(false);
  const [showDelAccVerificationModal, setShowDelAccVerificationModal] = useState(false);
  const [showEducationModal, setShowEducationModal] = useState(false);
  const [showEditEducationModal, setShowEditEducationModal] = useState(false);
  const [showDeleteEducationModal, setShowDeleteEducationModal] = useState(false);
  const [editStatus, setEditStatus] = useState(false);
  const [editPhone, setEditPhone] = useState(false);
  const [editAddress, setEditAddress] = useState(false);
  const [editEmail, setEditEmail] = useState(false);
  const [newPhone, setNewPhone] = useState('');
  const [newAddress, setNewAddress] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [oldEmail, setOldEmail] = useState('');
  const [error, setError] = useState(null); // Added state to hold error message  

  const dispatch = useDispatch();
  const user = useSelector((state) => state.user);

  useEffect(() => {
    const fetchUserData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;
          // Update your component state with the fetched data
          setNewEmail(userData.user_email);
          setOldEmail(userData.user_email);
          setUserData(userData);

          if (userData.user_picture != '') {
            fetchAndDisplayImage(userData.user_picture);
          }

        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchUserData();
  }, [user]);  // Run the effect whenever the user changes

  useEffect(() => {
    const fetchEducationData = async () => {
      if (user) {
        try {
          // Make a POST request to the server with the user's email
          const response = await axios.post('http://localhost:5000/api/account/', {
            email: user
          });
          // Assuming the server responds with the user data
          const userData = response.data.user;

          const eduResponse = await axios.post('http://localhost:5000/api/education/', {
            user_ID: userData.user_ID
          });
          const userEduData = eduResponse.data;
          setEducationData(userEduData);
        } catch (error) {
          console.error('Error fetching user data:', error);
        }
      }
    };

    fetchEducationData();
  }, [user, educationData]);  // Run the effect whenever the user changes

  const openPasswordModal = () => {
    if (oldEmail != newEmail) {
      const newUser = {
        user_ID: userData.user_ID,
        user_email: newEmail
        // Add other user properties here
      };
      // Make a POST request to your backend to save the user in the database
      axios.post('http://localhost:5000/api/verifyemail', newUser)
        .then(response => {
          // Display success message
          openEmailModal(); // Show the success modal
          // Clear error state
          setError(null);
          setShowPasswordModal(true);
        })
        .catch(error => {
          console.error('Error sending code:', error);

          if (error.response) {
            // The request was made and the server responded with a status code
            // that falls out of the range of 2xx
            if (error.response.status === 409) {
              // HTTP status code 409 indicates conflict, meaning the resource already exists
              setError('This email is already registered. Please use a different email.');
            } else {
              setError('Registration failed. Please try again.');
            }
          } else if (error.name === 'MongoServerError') {
            // Check if it's a MongoDB duplicate key error
            if (error.code === 11000) {
              setError('This email is already registered. Please use a different email.');
            } else {
              setError('Registration failed. Please try again.');
            }
          } else if (error.isAxiosError && error.response?.status === 500) {
            // Axios error with a 500 status code, indicating a server error
            setError('Registration failed. Please try again.');
          } else if (error.request) {
            // The request was made but no response was received
            setError('No response from the server. Please try again later.');
          } else {
            // Something happened in setting up the request that triggered an Error
            setError('An unexpected error occurred. Please try again.');
          }
        });
    } //Checks if the email is updated
    else {
      setShowPasswordModal(true);
    }
  };

  const closePasswordModal = () => {
    setShowPasswordModal(false);
    setShowDeleteEducationModal(false);
    setShowNewPasswordModal(false);
  };

  function openEmailModal() {
    setShowEmailModal(true);
  }

  function closeEmailModal() {
    setShowEmailModal(false);
  }

  const handleUpdateWithVerification = async (password) => {
    // Implement your logic to verify the password and handle the update
    // Call your update function here
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: oldEmail,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        const updateResponse = await axios.post('http://localhost:5000/api/userUpdate/', userData);
        // Check if the update was successful
        if (updateResponse.status === 200) {
          const user = { user_email: newEmail };
          dispatch(loginSuccess(user));
          setEditStatus(false);
          closePasswordModal(); // Close the modal after successful update
        } else {
          console.error('Update unsuccessful');
        }

      } else {
        // Display an error message based on the response status
        console.error('verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }
  };

  const handleAddEducation = () => {
    setShowEducationModal(true)
  };

  const handleCloseEducation = () => {
    setShowEducationModal(false)
    setShowEditEducationModal(false);
  };

  const handleSaveEducation = async (object) => {
    try {
      const response = await axios.post('http://localhost:5000/api/saveEdu/', {
        user_ID: userData.user_ID,
        programme_id: object.major,
        start_year: object.startDate,
        end_year: object.endDate,
        grades: object.grades,
        files_url: object.fileURL,
        isVerified: 'Pending'
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        educationData.push({
          user_ID: userData.user_ID,
          programme_id: object.major,
          start_year: object.startDate,
          end_year: object.endDate,
          grades: object.grades,
          files_url: object.fileURL
        });

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }
    } catch (error) {
      console.error('Error during save:', error);
    }
  };

  const handleOpenEducation = (params) => {
    setSelectedEducation(params);
    setShowEditEducationModal(true);
  }

  const handleEditEducation = async (object) => {
    // Implement your logic to edit education data
    try {
      const response = await axios.post('http://localhost:5000/api/editEdu/', {
        user_ID: userData.user_ID,
        programme_id: object.major,
        start_year: object.startDate,
        end_year: object.endDate,
        grades: object.grades,
        files_url: object.fileURL,
        isVerified: 'Pending'
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        educationData.push({
          user_ID: userData.user_ID,
          programme_id: object.major,
          start_year: object.startDate,
          end_year: object.endDate,
          grades: object.grades,
          files_url: object.fileURL
        });

      } else {
        // Display an error message based on the response status
        console.error('Edit unsuccessful');
      }
    } catch (error) {
      console.error('Error during edit:', error);
    }
  };

  const handleDeleteEducation = (params) => {
    // Implement your logic to edit education data
    setSelectedEducation(params);
    console.log("This is reached");
    setShowDeleteEducationModal(true);
  };

  const handleRemoveEducation = async (password) => {
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: oldEmail,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        const object = selectedEducation;
        console.log(object.programme.programme_id)
        try {
          const delResponse = await axios.post('http://localhost:5000/api/delEdu/', {
            user_ID: userData.user_ID,
            programme_id: object.programme.programme_id
          });
          // Check if the response is successful (status code 200-299)
          if (delResponse.status >= 200 && delResponse.status < 300) {

            educationData.pop();

            closePasswordModal(); // Close the modal after successful delete
          } else {
            // Display an error message based on the response status
            console.error('Delete unsuccessful');
          }
        } catch (error) {
          console.error('Error during deletion:', error);
        }

      } else {
        // Display an error message based on the response status
        console.error('verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during verification:', error);
    }

  };

  const handleEditClick = (field) => {
    // Set the appropriate state to enable editing
    if (field === 'phone') {
      setEditPhone(true);
    } else if (field === 'address') {
      setEditAddress(true);
    } else if (field === 'email') {
      setEditEmail(true);
    }
  };

  const handleCancelClick = (field) => {
    // Reset the input field and disable editing
    if (field === 'phone') {
      setNewPhone('');
      setEditPhone(false);
    } else if (field === 'address') {
      setNewAddress('');
      setEditAddress(false);
    } else if (field === 'email') {
      setNewEmail('');
      setEditEmail(false);
    }
  };

  const updateUserData = (field, data) => {
    setEditStatus(true);
    userData[field] = data;
  }

  const handleUpdateClick = async (field) => {
    // Implement logic to update the database with the new information
    // You might want to include password verification here

    // Assuming a function `updateUserData` that handles the update
    try {
      if (field === 'phone') {
        await updateUserData('user_phone', newPhone);
        setEditPhone(false);
      } else if (field === 'address') {
        await updateUserData('user_address', newAddress);
        setEditAddress(false);
      } else if (field === 'email') {
        await updateUserData('user_email', newEmail);
        setEditEmail(false);
      }

      // You can fetch updated user data again to refresh the display
    } catch (error) {
      console.error('Error updating user data:', error);
      // Handle the error, show a message, etc.
    }

  };

  const handleFileChange = async (event) => {
    const file = event.target.files[0];
    setSelectedFile(file);
    handleUpload(file);
  };

  function refreshPage() {
    window.location.reload(false);
  }

  const handleUpload = async (file) => {
    try {
      const formData = new FormData();
      formData.append('file', file); // Add the name attribute

      if (userData.user_picture != '') {
        const deleteResponse = await axios.delete(`http://localhost:5000/deleteProfilePicture/${userData.user_picture}`);
      }

      const response = await axios.post('http://localhost:5000/upload', formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'user': userData.user_ID
        },
      });

      fetchAndDisplayImage(response.data.message); // Assuming your server sends back the image filename
      refreshPage();
      // Optionally, you can fetch and display the uploaded images here
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };

  // Function to fetch and display the image on the screen

  const fetchAndDisplayImage = async (filename) => {
    try {
      // Fetch the image from the server
      const imageUrl = `http://localhost:5000/files/${filename}`;
      const response = await fetch(imageUrl);

      if (response.ok) {
        // Convert the response to blob and create an object URL
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        setImgURL(objectUrl);

      } else {
        console.error('Error fetching image:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const handleCameraClick = () => {
    // Trigger the file input click when the camera icon is clicked
    document.getElementById('fileInput').click();
  };

  const handleChangePassword = () => {
    setShowNewPasswordModal(true);
  };

  const closeUpdatePasswordModal = () => {
    setShowResetPasswordModal(false);
  }

  const handlePasswordVerification = async (password) => {
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: oldEmail,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setShowNewPasswordModal(false);

        setShowEmailVerificationModal(true);

      } else {
        // Display an error message based on the response status
        console.error('Verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during Verification:', error);
    }
  };

  const handleEmailVerification = async (password) => {
    setShowEmailVerificationModal(false);
    setShowResetPasswordModal(true);
  }

  const handleEmailVerificationClose = () => {
    setShowEmailVerificationModal(false);
  }

  const handleUpdatePassword = async (newPassword) => {
    // Call API to update the password in the database
    try {
      const response = await axios.post('http://localhost:5000/api/reset/', {
        user_ID: userData.user_ID,
        password: newPassword
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        const modal = document.querySelector('.modal-container');
        modal.classList.add('active');

        // Automatically close the modal after 3000 milliseconds (3 seconds)
        setTimeout(() => {
          modal.classList.add('fadeOut');
          modal.classList.remove('active');
          setTimeout(() => {
            modal.classList.remove('fadeOut');
          }, 500); // Adjust this duration to match the CSS animation duration
        }, 3000);

        setShowResetPasswordModal(false);

      } else {
        // Display an error message based on the response status
        console.error('Update unsuccessful');
      }
    } catch (error) {
      console.error('Error during update:', error);
    }
    // Handle the password update process
  };

  const handleDeleteAccount = () => {
    setShowConfirmDelAccModal(true);
  }

  const cancelDeleteAccount = () => {
    setShowDelAccModal(false);
    setShowDelAccVerificationModal(false);
    setShowConfirmDelAccModal(false);
  }

  const confirmDeleteAccount = () => {
    setShowDelAccModal(true);
    setShowConfirmDelAccModal(false);
  }

  const executeDeleteAccount = async (password) => {
    setShowDelAccVerificationModal(false);

    try {
      const response = await axios.post('http://localhost:5000/api/delete/', {
        user_ID: userData.user_ID
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setShowDelAccModal(false);

        setShowDelAccVerificationModal(true);

        dispatch(logout());

      } else {
        // Display an error message based on the response status
        console.error('Verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during Verification:', error);
    }
  }

  const handlePasswordVerification2 = async (password) => {
    try {
      const response = await axios.post('http://localhost:5000/api/login/', {
        email: oldEmail,
        password: password
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {

        setShowDelAccModal(false);

        setShowDelAccVerificationModal(true);

      } else {
        // Display an error message based on the response status
        console.error('Verification unsuccessful');
      }
    } catch (error) {
      console.error('Error during Verification:', error);
    }
  };

  const dobAsDate = new Date(userData.dob);
  const formattedBirthDate = dobAsDate instanceof Date
    ? `${dobAsDate.getDate()}/${dobAsDate.getMonth() + 1}/${dobAsDate.getFullYear()}`
    : userData.dob;

  const processedData = educationData.map((education) => {
    if (educationData.length != 0) {
      const endYear = new Date(education.userEdu.end_year).getFullYear();
      const currentYear = new Date().getFullYear();
      const status = endYear >= currentYear ? "Ongoing" : "Ended";
      // Return the education object with the added status field
      return {
        ...education,
        status: status
      };
    }
  });

  return (
    <div>
      <div className='account-tab'>
        <div className="account-info">
          <h2>General Information</h2>
          <p><span>First Name:</span> <span className="info-data">{userData.user_fname}</span></p>
          <p><span>Last Name:</span> <span className="info-data">{userData.user_lname}</span></p>
          <p><span>Gender:</span> <span className="info-data">{userData.user_gender}</span></p>
          <p><span>Birth Date:</span> <span className="info-data">{formattedBirthDate}</span></p>
          <p><span>Email: </span>
            {editEmail ? (
              <input
                type="text"
                value={newEmail}
                onChange={(e) => setNewEmail(e.target.value)}
              />
            ) : (
              <span className="info-data">{userData.user_email}</span>
            )}
            {editEmail ? (
              <>
                <FontAwesomeIcon className='edit-cancel' icon={faTimes} onClick={() => handleCancelClick('email')} />
                <FontAwesomeIcon className='edit-confirm' icon={faCheck} onClick={() => handleUpdateClick('email')} />
              </>
            ) : (
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick('email')} />
            )}
          </p>
          <p><span>Phone: </span>
            {editPhone ? (
              <input
                type="text"
                value={newPhone}
                onChange={(e) => setNewPhone(e.target.value)}
              />
            ) : (
              <span className="info-data">{userData.user_phone}</span>
            )}
            {editPhone ? (
              <>
                <FontAwesomeIcon className='edit-cancel' icon={faTimes} onClick={() => handleCancelClick('phone')} />
                <FontAwesomeIcon className='edit-confirm' icon={faCheck} onClick={() => handleUpdateClick('phone')} />
              </>
            ) : (
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick('phone')} />
            )}
          </p>
          <p><span>Address: </span>
            {editAddress ? (
              <input
                type="text"
                value={newAddress}
                onChange={(e) => setNewAddress(e.target.value)}
              />
            ) : (
              <span className="info-data">{userData.user_address}</span>
            )}
            {editAddress ? (
              <>
                <FontAwesomeIcon className='edit-cancel' icon={faTimes} onClick={() => handleCancelClick('address')} />
                <FontAwesomeIcon className='edit-confirm' icon={faCheck} onClick={() => handleUpdateClick('address')} />
              </>
            ) : (
              <FontAwesomeIcon icon={faEdit} onClick={() => handleEditClick('address')} />
            )}
          </p>
        </div>
        <div className="profile-header">
          <input
            type="file"
            id="fileInput"
            accept="image/*"
            style={{ display: 'none' }}
            onChange={handleFileChange}
          />
          <img className="profile-picture" id='profile-picture' src={imgURL} alt="Profile" />
          <FontAwesomeIcon icon={faCamera} className="camera-icon profile-picture-upload" onClick={handleCameraClick} />
          {/* Additional profile details if needed */}
        </div>
      </div>
      {/* Display error message */}
      {error && <div className="error-message">{error}</div>}
      {editStatus && (
        <button className='action-button' onClick={openPasswordModal}>Confirm Changes</button>
      )}
      <div className="education-section">
        <h2>Education History</h2>
        <table className='education-table'>
          <thead>
            <tr>
              <th>University</th>
              <th>Level</th>
              <th>Major</th>
              <th>Grades</th>
              <th>Status</th>
              <th>Verified</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {processedData.map((education) => (
              <tr key={processedData.indexOf(education) + 1} className="education-item">
                {(education.university.university_abbr != '') ? (
                  <td>{education.university.university_abbr}</td>
                ) : (
                  <td>{education.university.university_name}</td>
                )
                }
                <td>{education.programme.programme_level}</td>
                <td>{education.programme.programme_name}</td>
                <td>{education.userEdu.grades}</td>
                <td>{education.status}</td>
                <td>{education.userEdu.isVerified}</td>
                <td>
                  <FontAwesomeIcon icon={faEdit} onClick={() => handleOpenEducation(education)} />
                  &nbsp;&nbsp;
                  <FontAwesomeIcon icon={faTrash} onClick={() => handleDeleteEducation(education)} />
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        <button className='action-button education-button' onClick={handleAddEducation}>Add Education History</button>
      </div>
      <button className='action-button change-password' onClick={handleChangePassword}>Change Password</button>
      <button className='action-button delete-account' onClick={handleDeleteAccount}>Delete Account</button>
      {/* Display password verification modal */}
      {showPasswordModal && (
        <PasswordModal
          handleUpdate={handleUpdateWithVerification}
          closeModal={closePasswordModal}
        />
      )}
      {/* Display email verification modal */}
      {showEmailModal && (
        <VerificationModal
          userID={userData.user_ID}
          closeModal={closeEmailModal}
        />
      )}
      {showEducationModal && (
        <EducationModal handleClose={handleCloseEducation} handleSave={handleSaveEducation} object={null} />
      )}
      {showEditEducationModal && (
        <EducationModal handleClose={handleCloseEducation} handleSave={handleEditEducation} object={selectedEducation} />
      )}
      {showDeleteEducationModal && (
        <PasswordModal
          handleUpdate={handleRemoveEducation}
          closeModal={closePasswordModal}
        />
      )}
      {showNewPasswordModal && (
        <PasswordModal
          handleUpdate={handlePasswordVerification}
          closeModal={closePasswordModal}
        />
      )}
      {showEmailVerificationModal && (
        <EmailVerificationModal
          closeModal={handleEmailVerificationClose}
          onSuccess={handleEmailVerification}
          userEmail={oldEmail}
        />
      )}
      {showResetPasswordModal && (
        <NewPasswordModal
          handleUpdate={handleUpdatePassword}
          closeModal={closeUpdatePasswordModal}
        />
      )}
      {showDelAccModal && (
        <PasswordModal
          handleUpdate={handlePasswordVerification2}
          closeModal={cancelDeleteAccount}
        />
      )}
      {showDelAccVerificationModal && (
        <EmailVerificationModal
          closeModal={cancelDeleteAccount}
          onSuccess={executeDeleteAccount}
          userEmail={oldEmail}
        />
      )}
      {/* Delete Account Modal */}
      {showConfirmDelAccModal && (
        <div className="admin-modal-background">
          <div className="admin-modal">
            <h3>Delete Account</h3>
            <p>Are you sure you want to delete your account?</p>
            <div>
              <button className="action-button delete-acc-button" onClick={cancelDeleteAccount}>Cancel</button>
              <button className="action-button delete-acc-button" onClick={confirmDeleteAccount}>Confirm</button>
            </div>
          </div>
        </div>
      )}
      {/* Display success message */}
      <div className="modal-container">
        <div className="success-message modal-content">
          <p>Password Reset successful! You can now login with your new password.</p>
        </div>
      </div>
    </div>
  );
};

export default Account;