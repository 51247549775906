// actions.js
export const loginSuccess = (user) => ({
  type: 'LOGIN_SUCCESS',
  payload: user.user_email,
});

export const logout = () => ({
  type: 'LOGOUT'
});

export const retrieveUser = () => ({
  type: 'RETRIEVE_USER'
});