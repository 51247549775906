import React from 'react';
import Rating from 'react-rating';
import './../stylesheet/starRatings.css';

const StarRating = ({ rating }) => {
  return (
    <div className='starBox'>
        <span className='ratingText'>Rating: { rating }</span>
    <Rating
      initialRating={rating}
      emptySymbol={<span className="star">&#9734;</span>}
      fullSymbol={<span className="star">&#9733;</span>}
      readonly={true}
    />
    </div>
  );
};

export default StarRating;