import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './../stylesheet/payment.css';
import './../stylesheet/button.css';
import logo from './../../images/logo.png';
import StarRating from './starRatings';
import DropIn from 'braintree-web-drop-in-react';
import { useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import axios from 'axios';

const Payment = ({ }) => {
  const [courseData, setCourseData] = useState({});
  const [imgURL, setImgURL] = useState(logo);

  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);

  const subjectID = queryParams.get('subjectID');
  const userID = queryParams.get('userID');
  const programmeID = queryParams.get('programmeID');
  const universityID = queryParams.get('universityID');
  const user = useSelector((state) => state.user);
  const [userData, setUserData] = useState({});
  
  const navigate = useNavigate();
  const isLoggedIn = useSelector((state) => state.isLoggedIn);
  useEffect(() => {
    // Check if the user is already logged in
    // Assuming isLoggedIn and setIsLoggedIn are passed as props
    if (!isLoggedIn) {
      navigate('/login');
    }
  }, [isLoggedIn]);

  const calcAvg = (array, prop) => {
    if (array.length == 0) {
        return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
        total += array[i][prop];
    }
    let avg = total / array.length;
    return avg.toFixed(1);
}

  const calcSum = (array, prop) => {
    if (array.length == 0) {
      return 0;
    }
    let total = 0;
    for (let i = 0; i < array.length; i++) {
      total += array[i][prop];
    }
    return total;
  }

  useEffect(() => {
    const fetchData = async () => {
      try {
        const userResponse = await axios.post('http://localhost:5000/api/account/', {
          email: user
        });
        // Assuming the server responds with the user data
        const userData = userResponse.data.user;
        // Update your component state with the fetched data
        setUserData(userData);

        const response = await axios.get(`http://localhost:5000/api/courseRoute/`, {
          params: {
            subjectID: subjectID,
            userID: userID,
            programmeID: programmeID,
            universityID: universityID,
          },
        });
        if (response.data.user.isBanned) {
          navigate('/');
        }
        // Update state with the new data
        setCourseData(response.data);
        fetchAndDisplayImage(response.data.user.user_picture);
      } catch (error) {
        console.error('Error fetching course data:', error);
      }
    };

    fetchData();
  }, [subjectID, userID, programmeID, universityID]);

  const fetchAndDisplayImage = async (filename) => {
    try {
      // Fetch the image from the server
      const imageUrl = `http://localhost:5000/files/${filename}`;
      const response = await fetch(imageUrl);

      if (response.ok) {
        // Convert the response to blob and create an object URL
        const blob = await response.blob();
        const objectUrl = URL.createObjectURL(blob);

        setImgURL(objectUrl);

      } else {
        console.error('Error fetching image:', response.statusText);
      }
    } catch (error) {
      console.error('Error fetching image:', error);
    }
  };

  const [hours, setHours] = useState(1);
  const [dropInInstance, setDropInInstance] = useState(null);

  const handlePaymentSuccess = async () => {
    try {

      const payload = await dropInInstance.requestPaymentMethod();
      // Simulate a successful payment

      const response = await axios.post('http://localhost:5000/api/pay/', {
        payment_method: 'Credit/Debit',
        payment_status: 'Approved',
        payment_amount: hours * courseData.course.price.$numberDecimal,
        subject_id: courseData.subject.subject_id,
        tutee_id: userData.user_ID,
        tutor_id: courseData.course.user_ID,
        class_hour: hours
      });
      // Check if the response is successful (status code 200-299)
      if (response.status >= 200 && response.status < 300) {
        setTimeout(() => {
          // For demo purposes, you would typically send the nonce to your server for further processing
          // handlePaymentSuccess();
          // ...

          // Redirect to the order summary page
          navigate('/user/booking-history');
        }, 2000); // 2000 milliseconds = 2 seconds

      } else {
        // Display an error message based on the response status
        console.error('Save unsuccessful');
      }

    } catch (error) {
      console.error('Error requesting payment method:', error);
    }
  };

  const handleHoursChange = (e) => {
    setHours(e.target.value);
  };

  const calculateTotalPayment = () => {
    // Replace this with your actual pricing logic
    const pricePerHour = courseData.course.price.$numberDecimal;
    return hours * pricePerHour;
  };

  return (
    <div className="order-summary-page">
      {courseData.user && (
        <>
          <div className="order-summary-container">
            <header>
              <h1>Booking Summary</h1>
            </header>

            <section className="order-summary">
              {/* Display course details here */}
              <div className="payment-profile-info">
                <div>
                  <img className="profile-picture" src={imgURL} alt="Profile" />
                  <StarRating rating={calcAvg(courseData.ratings, "rating")} />
                </div>
              </div>
              <div className="payment-course-info">
                <p><span>Subject Name:</span> {courseData.subject.subject_code} - {courseData.subject.subject_name}</p>
                <p><span>Tutor Name:</span> {courseData.user.user_fname} {courseData.user.user_lname}</p>
                <p><span>CGPA:</span>{courseData.userEdu.grades}</p>
                <p><span>Grade Obtained:</span> {courseData.course.grade}</p>
                <p><span>No. Students:</span> {courseData.students.length}</p>
                <p><span>Hours Taught:</span> {calcSum(courseData.students, "class_hour")}</p>
              </div>

              {/* Calculator for total payment */}
              <div className="payment-calculator">
                <div>
                  <h2>Total Amount Payable</h2>
                  <span className='pricing-rate'>RM/Hr:<br /><span className='price'>{courseData.course.price.$numberDecimal}</span></span>
                  <label htmlFor="hours">Number of Hours:</label>
                  <input
                    type="number"
                    id="hours"
                    name="hours"
                    min="1"
                    value={hours}
                    onChange={handleHoursChange}
                  />

                  <p>Total Payment: <span id="total-payment">RM {calculateTotalPayment()}</span></p>
                </div>
                <div className='checkout-section'>
                  <div id="dropin-container"></div>
                  <DropIn
                    options={{
                      authorization: 'sandbox_tv2nfx3m_pkcg2w8tzjrzt3x5',
                      paypal: {
                        flow: 'vault',
                      },
                    }}
                    onInstance={(instance) => setDropInInstance(instance)}
                  />
                  <button className="action-button" id="submit-button" onClick={handlePaymentSuccess}>
                    Checkout
                  </button>
                </div>
              </div>
            </section>
            {/* Checkout button */}

          </div>
        </>
      )}
    </div>
  );
};

export default Payment;
