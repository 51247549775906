import React, { useState } from 'react';
import Rating from 'react-rating';
import './../stylesheet/starRatings.css';

const StarRatingInput = ({ onChange }) => {
  const [rating, setRating] = useState(0);

  const handleRatingChange = (value) => {
    setRating(value);
    onChange(value);
  };

  return (
    <div className='starBox'>
      <span className='ratingText'>Rating: {rating}</span>
      <Rating
        initialRating={rating}
        emptySymbol={<span className="star">&#9734;</span>}
        fullSymbol={<span className="star">&#9733;</span>}
        onClick={handleRatingChange}
      />
    </div>
  );
};

export default StarRatingInput;
